/* Page title, expand
   ========================================================================== */

  .page-title-container{
    position:relative;
    margin-bottom:0;
   }

  .page-title {
    width:100% / 12 * 6;
    left:40px;
    right:60px;
    position:absolute;
    padding-bottom:80px;
    box-sizing:border-box;
    bottom:0;
    background:#FFF;

    .inner {
      padding-right:16.5%;
      padding-left:16%;
    }

    h1 {
      font-size:37px;
      font-weight:900;
      text-transform: uppercase;
      letter-spacing:0.060em;
      margin-bottom:0em;
      min-width:400px;
    }

    .emotion-subhead {
      font-family:'Lora';
      font-size:17px;
      line-height:22px;
      color:$sander_gold_dunkel;
      margin-bottom:20px;
      margin-top:20px;
    }

    p {
      font-size:14px;
      line-height:20px;
    }

    > p, > h1, > a {
      z-index:2;
      position:relative;
    }


    &:before {
      content:'';
      display:block;
      position:absolute;
      top:-140px;
      background-image:url($uri-icon-page-title-background);
      background-size:cover;
      background-position:0 0;
      background-repeat:no-repeat;
      width:100%;
      height:140px;
      left:0;
    }

    @include gridle-state('tablet') {
      max-width:525px;
      width:50%;
      padding-bottom:40px;
      .inner {
        position:relative;
        padding-left:45px;
        padding-right:65px;
        margin-top:-45px;
        z-index:1;
      }
      h1 {
        color:$grau_51;
        font-size:30px;
        line-height:32px;
        min-width:auto;
      }
    }

    @include gridle-state('tablet-s') {
      width:auto;
    }

    @include gridle_state('phone') {
      left: 0;
      right: auto;
      position: relative;
      top: 0;
      padding-bottom:0;

      .inner {
        padding-right:25px;
        padding-left:20px;
        padding-bottom:40px;
      }
      &:before {
        top:-100px;
      }
    }
  }


.page-title-expand {
  .expand-content {
    @include gridle(9 push 1);
  }

  h2 {
    font-size:14px;
    margin-top:0;
  }

  background:#FFF;
  .inner {
    padding:40px 0 80px 0;
  }

  .expand-text-left {
    @include gridle(6);
  }

  .expand-text-right {
    @include gridle(6 push 1);
  }

  @include gridle_state('tablet') {
    .expand-content {
      @include gridle(12 push 0);
    }
    .expand-text-left {
      @include gridle(5);
    }
    .expand-text-right {
      @include gridle(5 push 1);
    }
    .inner {
      padding-left:45px;
      padding-right:45px;
    }
  }

  @include gridle_state('tablet-s') {
    .expand-text-right {
      margin-top:20px;
    }
    .expand-text-left, .expand-text-right {
      @include gridle(12 push 0);
    }
  }

  @include gridle_state('phone') {
    .inner {
      padding-top:0;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom:40px;
    }
  }
}
