.section-single {
  padding-top:60px;
  padding-bottom:60px;

  background:white;

  .meta-title, h2 {
    font-family:$lora;
    font-style:italic;
    font-weight:bold;
    font-size:17px;
    line-height:22px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color:$sander-gold-dunkel;
  }

  h1 {
    font-size:32px;
    line-height:36px;
    margin-top:5px;
  }

  h2 {
    margin-bottom:0;
    &:first-child {
      margin-top:0;
    }

    .aktionswochen & {
      margin-bottom: 1em;
      font-style:normal;
    }
  }

  .single-further {
    margin-top:40px;
    padding-top:60px;
    border-top:1px solid $grau_62;
  }

  .single-column-left, .single-column-right {
    @include gridle(6);
  }

  /* Scale down images */
  .single-main p > img {
    max-width:100%;
    height:auto;
  }

  .col-download {
    @include gridle(2/10);
  }
  .col-person {
    @include gridle(3/10 push 1);
    > .contact-person {
      margin-top:0;
    }
    &.news-col-person {
      @include gridle(12 push 0);
    }
  }
  .col-info {
    @include gridle(3/10 push 2);
  }

  @include gridle_state(tablet) {
    padding-top:40px;
    padding-bottom:40px;

    .col-download, .col-person, .col-info  {
      @include gridle(12 push 0);
    }
  }

  @include gridle_state(tablet-s) {
    .single-column-left, .single-column-right {
      @include gridle(12);
    }
    .single-column-right {
      margin-top:1em;
    }
  }

  @include gridle_state(phone) {
      padding-top:20px;

      h1 {
        font-size:26px;
        line-height:31px;
      }
  }
}
