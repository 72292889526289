/* ==========================================================================
   Detailed teaser
   ========================================================================== */
.teaser-group-detailed {
  margin-top:20px;
}


.detailed-teaser {
  position:relative;
  height:400px;
  @include gridle(5 push 1);

  .teaser-text {
    // max-width:80%;
  }

  .teaser-inner.dark {
    p { color: white; }
  }

  .teaser-text.halfwidth {
    max-width:50%;
  }

  .teaser-inner {
    padding-top:40px;
    position:absolute;
    height:inherit;
    box-sizing: border-box;
    bottom:0;
    right:10px;
    left:10px;
    display:flex;
    flex-direction:column;
    justify-content: flex-end;

    h2.teaser-headline {
      height:auto;
      margin-bottom:10px;
    }

    .teaser-content-wrapper {
      display:block;
      height:auto;
    }
  }

  .button.btn-gold {
    margin-top:10px;
  }

  &.has-seal {
    padding-top:40px;
  }

  .teaser-top-seal {
    background-image:url($uri-icon-teaser-label-bg);
    background-repeat:no-repeat;
    width:270px;
    height:180px;
    position:absolute;
    display:block;
    z-index: 1;
    top:-40px;
    left:30px;

    img {
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      max-width:190px;
      height:auto;
      display:block;
      margin:0 auto;
      padding-bottom:45px;
    }
  }

  .icon.icon-shopping-cart {
    width:28px;
    height:28px;
    background-size:28px 28px;
  }

  .badge-award-2018-nominee {
    position:absolute;
    width:40px;
    height:50px;
    z-index: 1;
    top:10px;
    left:20px;
  }

  /* everything over */
  @media screen and (min-width: 768px) {
    .badge-award-2018-nominee {
      position:absolute;
      width:40px;
      height:50px;
      z-index: 1;
      top:10px;
      left:50px;
    }
  }



  @include gridle_state(tablet) {
    @include gridle(6 push 0);

    height:360px;

    .teaser-top-seal {
      width:200px;
      height:140px;
      img {
        max-width:130px;
      }
    }

    .teaser-inner .teaser-headline {
      font-size:30px;
    }

    p.teaser-text {
      max-width:100%;
      font-size:14px;
    }
    p.teaser-text-award {
      max-width:100%;
      font-size:14px;
    }

  }

  @include gridle_state(tablet-s) {
    .teaser-inner {
      .teaser-content-wrapper {
        padding:0;
      }
      padding:20px;
    }

    .teaser-top-seal {
      background-position: -20px 0;
      img {
        margin-left:-20px;
      }
    }
  }

  @include gridle_state(phone) {
    margin-top:20px;
    &.has-seal {
      margin-top:60px;
    }
    @include gridle(12);

    &:first-child {
      margin-top:0;
    }
  }
}
