.testimonial-slider {
  @include gridle(10 push 1);

  position:relative;
  margin-top:20px;
  padding: 40px 0;
  padding-top:0;
  text-align:center;

  .small-title-headline {
    text-align:center;
    font-weight:bold;
    display:block;
    margin:0 auto;
    margin-bottom:20px;
  }

  .quote-large {
    font-family:$lora;
    font-size:30px;
    line-height:37px;
    max-width:900px;
    margin:0 auto;
  }

  .slide-text {
    // margin-top:20px;
    // margin-bottom:20px;

    .info-line {
      margin-top:10px;
      color:$sander_gold_dunkel;
    }

    .name {
      text-transform: uppercase;
      font-weight:bold;
      letter-spacing: 0.080em;
    }

    .position:before {
      content:'| ';
    }

    .company:before {
      content:'| ';
    }
  }

  .slide-container {
    position:relative;
  }

  .slide-btn {
    top:50%;
    transform:translateY(-50%);
    position:absolute;
    svg {
      fill:$sander_gold_dunkel;
      transition: fill .1s;
    }

    &:hover {
      svg {
        fill:$sander_gold_hell;
      }
    }
  }

  .slide-btn-left {
    left:-25px;
  }

  .slide-btn-right {
    right:-25px;
  }

  .slide {
    padding-top:30px;
    padding-bottom:30px;
  }

  .slide-logos {
    ul {
      list-style:none;
    }
    li {
      @include gridle(1/6);
      justify-content: middle;
      margin-bottom:0;
      img {
        max-height:70px;
        max-width:130px;
      }
    }
  }

  .slider-dots {
    list-style:none;
    padding:0;
    margin:0;

    .dot {
      display:inline-block;
      margin:0 4px;
      border-radius:50%;
      background-color:$grau_73;
      width:12px;
      height:12px;
      cursor:pointer;

      &.active, &:hover {
        background-color:$sander_gold_dunkel;
      }
    }
  }

  @include gridle_state(tablet) {

    .name {
      display:block;
    }

    .position:before {
      display:none;
    }

    .slide {
      padding-top:20px;
      padding-bottom:20px;
    }

    .quote-large {
      font-size:26px;
    }
    .slide-logos li {
      @include gridle(1/3);
      margin-bottom:20px;
    }
  }

  @include gridle_state(phone) {
    .small-title-headline {
      font-size:17px;
      line-height:20px;
      font-weight:normal;
      margin-bottom:20px;
    }
    .quote-large {
      font-size:21px;
      line-height:30px;
    }

    .slide-logos {
      li {
        display:none;
        @include gridle(12);
        margin-bottom: 40px;
      }
      li:nth-child(1) {
        display:block;
      }
      li:nth-child(2) {
        display:block;
      }
      li:nth-child(3) {
        display:block;
      }
    }
  }
}
