.teasers-simple-film {
  overflow:hidden;
}

.teaser-simple {
  margin-bottom:0;
  z-index: 1;

  @include gridle(4 push 1);
  margin-top:20px;

  @include gridle_state(tablet) {
    height:220px;
    @include gridle(4 push 0);
  }

  @include gridle_state(tablet-s) {
    height:220px;
    @include gridle(6 push 0);
  }

  @include gridle_state(phone) {
    @include gridle(12 push 0);
  }
}

.teaser-film {
  margin-top:20px;
  position:relative;
  @include gridle(6 push 1);

  @include gridle_state(tablet) {
    height:220px;
    @include gridle(8 push 0);
  }

  @include gridle_state(tablet-s) {
    height:220px;
    @include gridle(6 push 0);
  }

  @include gridle_state(phone) {
    @include gridle(12 push 0);
  }

  .video-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    height:340px;
    position:static;
    @include gridle-state(tablet) {
      height:220px;
    }
  }

  video {
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    flex-grow: 0;
    flex-shrink: 0;
  }

  video::-internal-media-controls-download-button {
      display:none;
  }

  video::-webkit-media-controls-enclosure {
      overflow:hidden;
  }

  video::-webkit-media-controls-panel {
      width: calc(100% + 30px); /* Adjust as needed */
  }

}

.teaser-simple-inner {
  background-size:cover;
  background-repeat:no-repeat;
  height:340px;
  padding-top:60px;
  padding-bottom:40px;
  box-sizing:border-box;
  overflow:hidden;
  position:relative;

  .teaser-background {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    margin:auto;
    min-width:50%;
    min-height:50%;
    transition: transform .4s;
    transform-origin:50% 50%;
    pointer-events:none;
  }

  .button-container {
    text-align:center;
    position:absolute;
    bottom:40px;
    width:100%;
  }

  .seal {
    display:block;
    max-width:70%;
    margin:0 auto 0 auto;
    z-index: 2;
    position:relative;
  }

  .badge-award-2018-winner {
    position:absolute;
    width:95%;
    height:100px;
    z-index: 5;
    top:55px;
    left:10px;
    text-align: center;
  }

    &:hover {
      .teaser-background {
          transform:translate(-50%, -50%) scale(1.05);
      }
  }



  @include gridle_state(tablet) {
    padding-top:40px;
    height:220px;

    background-position:50% 50%;

    .seal {
      max-width:220px;
    }
  }
}


@media screen and (max-width: 1024px) {
  .film-splash {
min-height:350px;
  }
}

@media screen and (max-width: 575px) {
  .film-splash {
    min-height:220px;
  }
}

.film-splash {
  position:absolute;
  top:0;
  left:10px;
  right:10px;
  bottom:0;
  background:linear-gradient(to bottom, rgba(#b7b7b7, 1), rgba(white,1));
  z-index: 2;

  &:before {
    content:'';
    display:block;
    position:absolute;
    right:40px;
    top:40px;
    background-repeat: no-repeat;
    width:64px;
    height:64px;
    background-size:100% 100%;
    background-image:url('../images/logos/sander-logo.svg');
    background-color:#FFF;
    border:3px solid #FFF;
  }

  .btn.play {
    background-color:$sander_gold-hell;
    background-image:url($uri-icon-triangle-right);
    background-repeat:no-repeat;
    background-position:55% 50%;
    background-size: 20px auto;
    width:75px;
    height:75px;
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    border-radius:50%;
    box-shadow:3px 3px 12px rgba(0,0,0,0.65);
    transition: background-color .2s;

    &:hover {
      background-color:$sander_gold_dunkel;
    }

    @include gridle_state(tablet) {
      .film-title {
        bottom:10px;
      }
    }
  }

  .film-title {
    position:absolute;
    bottom:20px;
    left:50%;
    width:100%;
    transform:translate(-50%, 0);
    span {
      color:$sander_gold_dunkel;
    }
    .small-title-headline {
      margin-bottom:0;
    }
    text-align:center;
  }

  @include gridle_state(tablet) {

    .small-title-headline {
      font-size:10px;
      margin-bottom:0;
    }

    .teaser-headline {
      font-size:19px;
    }

    #teaser-headline-ausgezeichnet {
      font-size:26px !important;
    }

    @media screen and (min-width: 940px) {
      .teaser-headline-ausgezeichnet>h2 {
        font-size:26px;
      }
    }

    .film-title {
      bottom:10px;
    }
  }
}
