/* Tabview Module
   ========================================================================== */

.mod-sander-tabview {
  padding-top:120px;
  padding-bottom:80px;

  // Special styles if the tabview is not the single element in the section

    &.is-inside {
      padding-top:0;
      padding-bottom:0;
      margin-top:40px;
    }

  @include gridle_state(desktop-only) {
    &.is-inside {
      .tabview-segment-inner {
        padding-bottom:0;
      }
    }
  }

  .tabview-main {
    overflow:hidden;
  }

  .tabview-title {
    text-align: center;
    display:block;
    margin:0 auto;
    position:relative;
    text-transform: uppercase;
    letter-spacing:0.230em;
    font-family:'Lora';
    font-weight:bold;
    font-size:19px;
    line-height:24px;
    color:$sander_gold_dunkel;
    text-align:center;
    height:auto;

    &:after {
      content:'';
      display:block;
      height:40px;
      width:1px;
      position:absolute;
      top:100%;
      left:50%;
      background:$sander_gold_dunkel;
    }
  }

  .tabview-navigation {
    background:linear-gradient(to bottom, white, $grau_95);
    font-size:0; /* Fixes undesired space in FF */
    padding:0 10px;
    text-align:center;

    &.reverse {
      background:linear-gradient(to top, white, $grau_95);
      // border-top:20px solid $grau_95;
    }
  }

  .item-container {
    padding-top:100px;
    display:inline-flex;
    justify-content: center;
    position:relative;
    &:before {
      content:'';
      display:block;
      position:absolute;
      top:40px;
      right:105px;
      left:105px;
      height:1px;
      background:$sander_gold_dunkel;
    }
  }

  .item {
    display:flex;
    width:190px;
    height:180px;
    margin:0;
    position:relative;
    text-decoration:none;
    padding: 10px;
    box-sizing:content-box;
    justify-content: center;
    color:white;
    transition: background .3s;
    font-style:normal;
    font-family:$lato;
    border:none;

    &:before {
      display:block;
      content:'';
      background:$sander_gold_dunkel;
      width:1px;
      height:40px;
      position:absolute;
      top:-60px;
      left:50%;
    }

    &:after {
      display:block;
      content:'';
      position:relative;
      border-radius:50%;
      width:7px;
      height:7px;
      position:absolute;
      top:-25px;
      left:50%;
      margin-left:-3px;
      background:$sander_gold_dunkel;

    }


    .item-image-container {
      background:$sander_gold_dunkel;
    }

    .item-image-container {
      display:block;
      background-color:mix($sander_gold_dunkel, #FFF, 80%);
      position:absolute;
      width:190px;
      height:180px;
      transition: height .3s;
      overflow:hidden;
      left:10px;
      // background-size:cover;
      // background-position:50% 50%;
      img {
        height:200px;
        width:auto;
        opacity:0.7;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        transition: opacity .3s;
      }

      &:after {
        content:'';
        display:block;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background:linear-gradient(to bottom,rgba( white,0),rgba( black,1));
        opacity:0.55;
      }
    }

    .item-label {
      display:block;
      text-align:center;
      // width:100%;
      text-transform: uppercase;
      letter-spacing: 0.160em;
      font-size:14px;
      position:absolute;
      bottom:50px;
      line-height:16px;
      transition: bottom .3s;
      left:10px;
      right:10px;

        &:after {
          content:'';
          display:block;
          background-image:url($uri-icon-small-arrow-down-white);
          width:12px;
          height:8px;
          position:absolute;
          left:50%;
          margin-left:-6px;
          bottom:-20px;
          transition: transform .2s;
        }
    }

    &.active {
      background:white;
    }

    &:hover, &.active {
      border:none;
      .item-image-container {
        height:200px;
        &:after {
          // opacity:0;
        }
        img {
          opacity:1;
        }
      }

      .item-label {
        bottom:30px;
      }
    }

    &.active {
        .item-label:after {
          transform:rotate(180deg);
        }
    }
  }

  .item-container.following {
    padding-top:0;
    &:before {
      display:none;
    }
    .item:before {
      display:none;
    }
    .item:after {
      display:none;
    }
  }

  .tabview-segment {
    text-align:left;
    overflow:hidden;
    width:100%;
    // height:0;
  }

  .tabview-segment-inner {
    padding-top:100px;
    padding-bottom:80px;
    img {
      max-width:100%;
      height:auto;
    }
  }

  .tabview-segment-headline {
    text-transform: uppercase;
    font-family:Lora;
    font-size:12px;
    line-height:18px;
    color:$sander_gold_dunkel;
    letter-spacing:0.2em;
    display:block;
  }

  .tabview-item-downloads {
    .tabview-segment-inner {
      .col-remaining {
        margin-top:0;
      }
    }
    @include gridle_state(tablet) {
      .tabview-segment-inner {
        .col-remaining {
          margin-top:40px;
        }
      }
    }
  }

  .tabview-item-documents {
    .tabview-segment-inner {
      .download-col {
        margin-top:0;
      }
      padding-bottom:0;
    }
    @include gridle_state(tablet) {
      .col-content {
        order:2;
      }
      .download-col {
        padding-bottom:40px;
        order:1;
      }
    }
  }

  .section-headline {
    display:block;
    font-size:26px;
    line-height:28px;
    margin-top:6px;
  }

  @include gridle_state('tablet') {
    .item {
      &, .item-image-container {
        width:240px;
      }
      .item-image-container img {
        width:100%;
        height:auto;
      }
      &:hover {
        .item-image-container {
          height:180px;
        }
        .item-label {
          bottom:50px;
        }
      }
      &.active, &:hover.active {
        .item-image-container {
          height:200px;
        }
        .item-label {
          bottom:30px;
        }
      }
    }
    .item-container:before {
      right:130px;
      left:130px;
    }
    padding-bottom:40px;
  }

  @include gridle_state('tablet-s-only') {
    .item {
      &, .item-image-container {
        width:200px;
      }
      .item-image-container img {
        width:100%;
        height:auto;
      }
    }
    .item-container:before {
      right:110px;
      left:110px;
    }
  }

  @include gridle_state('phone') {
    h2.tabview-title {
      height:auto;
    }
    .item-container {
      padding-top:80px;
    }
  }
}


@include gridle-state('phone') {
  .mod-sander-tabview {
    padding-top:40px;
    h2.tabview-title {
      padding:0 40px;
      &:after {
        display:none;
      }
      &:before {
        display:none;
      }
    }
  }
}

.tabview-segment-standard {
  &.empty {
    .tabview-segment-inner {
      padding-top:60px;
      padding-bottom:40px;
    }
  }
  .tabview-segment-inner {
    .col-content {
      @include gridle(4 push 1);
    }
    .col-remaining {
      @include gridle(5 push 2);
    }

    @include gridle_state('tablet') {
      padding-top:60px;
      padding-bottom:60px;
      .col-content, .col-remaining {
        @include gridle(10 push 1);
      }
      .col-remaining {
        margin-top:30px;
      }
    }

    @include gridle_state('phone') {
      .col-content, .col-remaining {
        @include gridle(12 push 0);
        padding-right:40px;
        padding-left:40px;
      }
    }
  }

}

    @include gridle_state('desktop-s') {
      .section_leistungsangebot {
        .tabview-segment-standard {
          .tabview-segment-inner {
            .col-content {
              @include gridle(10 push 1);
            }
            .col-remaining {
              @include gridle(0);
              margin-top:30px;
            }
          }
        }
      }
    }
