/* Logo slider
   ========================================================================== */

.mod-logo-slider {
  height:150px;
  position:relative;

  .logo-slide-container {
    width:100%;
    height:inherit;
    position:relative;
    perspective:600px;
  }

  .logo-slide {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(0);
    transition: transform .5s, opacity .5s;
    opacity:1;
    user-select:none;
    img {
      display:block;
      user-select:none;
      -webkit-user-drag:none;
      max-width:170px;
      max-height:170px;
    }
    &.past {
      transform:translateX(-100px) rotateY(-70deg);
      opacity:0;
      pointer-events:none;
    }

    &.future {
      transform:translateX(100px) rotateY(70deg);
      opacity:0;
      pointer-events:none;
    }
  }


  .btn-logo-slide.left{
    position:absolute;
    left:0;
    top:50%;
    padding:0;
    margin:0;
  }
  .btn-logo-slide.right {
    position:absolute;
    right:0;
    top:50%;
    margin:0;
  }
  .btn-logo-slide {
    opacity:0.5;
    height:30px;
    display:block;
    transition:opacity .2s;
    transform:translateY(-50%);
    z-index:9;
    &:hover {
      opacity:1;
    }
  }

  @include gridle_state('tablet') {
    .btn-logo-slide {
      transform: translateY(-50%) scale(0.75);
      transform-origin:50% 50%;
    }
    .btn-logo-slide.right {
      right:-25px;
    }
    .btn-logo-slide.left {
      left:-25px;
    }
  }

  @include gridle_state('phone') {
    .btn-logo-slide.right {
      right:0;
    }
    .btn-logo-slide.left {
      left:0;
    }
    .logo-slide-container {
      width:70%;
      margin:0 auto;
    }
  }
}
