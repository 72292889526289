.teaser-download {
  padding-top:40px;
  margin-top:20px;

  .preview-container {
    @include gridle(3 push 0);


    img {
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      right:-30px;
      left:50px;
      width:auto;
      height:100%;
      z-index: 2;
      box-shadow:3px 3px 20px rgba(0,0,0,0.45);
      max-width:280px;
    }

    .icon-badge-new-home {
      position:absolute;
      top:-40px;
      left:0;
      z-index: 3;
      width:139px;
      height:139px;
      display:block;
    }
  }

  .content {
    padding-top:40px;
    padding-bottom:40px;
    @include gridle(7 push 0);
    strong {
      a{
        text-decoration: none;
        color:$grau_51;
      }
    }
  }

  .content-inner {
    padding-top:40px;
    padding-bottom:40px;
    background: white;
    text-align: center;
    padding-left:120px;
    padding-right:120px;
    // max-width: 450px;
    // margin:0 auto;
    .small-title-headline {
      color:$grau_51;
      margin-bottom:4px;
      font-weight:bold;
    }
    .teaser-headline {
      color:$sander_gold_dunkel;
      font-size:26px;
    }
    .new-text {
      color:$sander_gold_dunkel;
    }
  }

  @include gridle_state(desktop-s) {
    .content-inner {
      padding-left:80px;
      padding-right:80px;
    }
    .preview-container {
      img {
        height:100%;
        left:20px;
      }
    }
  }

  @include gridle_state(tablet) {
    .download-title {
      margin-bottom:10px;
    }
    .preview-container {
      @include gridle(4 push 0);
      img {
        left:40px;
        width:100%;
        height:auto;
      }
    }
    .content {
      @include gridle(8 push 0);
    }
    .icon-badge-new-home {
      width:60px;
      height:60px;
    }
  }

  // @include gridle_state(tablet-s) {
  //   .preview-container {
  //     @include gridle(5);
  //     min-width:280px;
  //   }
  //   .content {
  //     @include gridle(7);
  //   }
  //   .content-inner {
  //     padding-left:40px;
  //   }
  // }

  @include gridle_state(tablet-s) {
    margin-top:40px;
    .preview-container {
      margin-bottom:-20px;
      width:auto;
      .icon-badge-new-home {
        width: 100px;
        height: 100px;
        top:-40px;
        left:-20px;
      }
      flex-basis:233.3px;
      max-width:233.3px;
      height:330px;
      img {
        width:auto;
        height:inherit;
        left:50%;
        top:0;
        transform:translateX(-50%);
      }
    }
    .content {
      padding-top:10px;
      @include gridle(12);
    }
    .content-inner {
      padding: 40px 20px 20px 20px;
      .teaser-headline {
        margin-top:2px;
        font-size:20px;
        line-height:22px;
      }
    }
  }
}
