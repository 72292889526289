.page-section.section-form {
  padding-top:80px;
  padding-bottom:80px;
  margin-left:0;

  .sander-form .intro {
      max-width: initial;
      h2 {
          color:$sander_gold_dunkel;
          font-size:26px;
          line-height:31px;
      }
  }
}

.sander-form {

    select, .form-input-wrapper > input, textarea {
        font-size:15px;
        font-family:$lato;
    }

    select, .form-input-wrapper > input, textarea {
        background:transparent;
        border:none;
        border-bottom:1px solid $grau_73;
        color:$grau_51;
        padding-top:8px;
        padding-bottom:8px;
        padding-left:0;
        width:100%;
        box-sizing: border-box;

        &:focus {
          border-bottom:1px solid mix($sander_gold_hell, #FFF);
          outline:0;
        }
    }

    select {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-position: right 50%;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);

      color:gray;
      &.valid-selected {
        color:black;
      }
    }

    .intro {
        max-width:400px;
        margin-bottom:30px;
    }

    .form-label {
        font-weight:bold;
        font-size:13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom:15px;
    }
    .form-field {
        margin-top:30px;
        &:first-child {
            margin-top:0;
        }
    }
    .form-field.hide-label {
        margin-top:20px;
        .form-label {
            display:none;
        }
    }
    .form-field.space-after {
      margin-bottom:50px;
    }
    .buttons {
        margin-top:40px;
    }

    .radio {
      display:block;
      margin:10px;
      input {
        opacity:0;
        width:20px;
      }
      label {
        height:20px;
        font-size:15px;
        margin-left:-20px;
        span {
          vertical-align: bottom;
          display:inline-block;
          width:20px;
          height:20px;
          background-image:url($uri-icon-radio-unchecked);
          background-repeat: no-repeat;
          margin-right:7px;
        }
      }
      input:checked + label > span {
        background-image:url($uri-icon-radio-checked);
      }
    }

    .checkboxes {
      display:inline-block;
      margin-right:15px;
      input {
        opacity:0;
        width:20px;
      }
      label {
        height:20px;
        font-size:15px;
        margin-left:-20px;
        span {
          vertical-align: bottom;
          display:inline-block;
          width:20px;
          height:20px;
          background-image:url($uri-icon-radio-unchecked);
          background-repeat: no-repeat;
          margin-right:7px;
        }
      }
      input:checked + label > span {
        background-image:url($uri-icon-radio-checked);
      }
    }

    .checkbox {
      display:inline-block;
      margin-left:-5px;
      // padding-left:20px;
      input {
        opacity:1;
        width:20px;
      } /*
     label {
        height:20px;
        font-size:15px;
        margin-left:-20px;
        position: relative;
        .fake-box {
          vertical-align: bottom;
          display:inline-block;
          width:20px;
          height:20px;
          background-image:url($uri-icon-radio-unchecked);
          background-repeat: no-repeat;
          margin-right:7px;
          // margin-left:-24px;
        }
        .actual-label {
          display:inline-block;
          min-width:300px;
          position:absolute;
          top:50%;
          transform:translateY(-50%);
          padding-left:10px;
        }
      }
      input:checked + label > .fake-box {
        background-image:url($uri-icon-radio-checked);
      } */
    }


    .form-small-label {
      display:block;
      font-size:13px;
      line-height: 17px;
      margin-bottom:10px;
    }

    .form-left {
      @include gridle(6);
      padding-right:40px;
    }

    .form-right {
      @include gridle(6);
      padding-left:40px;
    }

    .mandatory-notice {
      font-size:15px;
      font-style:italic;
      margin-top:40px;
    }

    @include gridle_state(tablet) {
        .form-field {
            &:first-child {
                margin-top:30px;
            }
        }
        .form-left {
          @include gridle(12);
          padding-right:10px;
        }
        .form-right {
          @include gridle(12);
          padding-left:10px;
        }
    }
}

.contact-form {
    textarea {
        height:160px;
    }
}

.newsletter-form, .gewinnspiel-form {
      .buttons {
      margin-top:60px;
      padding-top:60px;
      border-top:1px solid $grau_62;
        text-align: right;

        .button {
          float:right;
          margin-top:10px;
        }

        .form-field {
          margin-top:0;
          float:left;
          display:block;
          text-align:left;
          clear:both;
          .actual-label {
            text-align: left;
            position:relative;
            transform:none;
            width:500px;
          }
        }
    }

    @include gridle_state(tablet-s) {
      .button {
        margin-top:20px;
      }
      .buttons {
        .checkbox label {
          display: block;
          margin:0;
          height:auto;
        }
        .form-field {
          float:none;
          .actual-label {
            width:auto;
            margin-left:20px;
          }
          .fake-box {
            float:none;
            display: block;
            position: absolute;
            top:50%;
            transform:translateY(-50%);
          }
        }
        .form-label {
          margin-bottom:0;
        }
      }
    }

    @include gridle_state(phone) {
      .checkbox label .actual-label {
        width:240px;
        min-width:auto;
        font-size:15px;
      }

      .buttons {
        padding-top:40px;
        .button {
          margin-top:60px;
        }
      }
    }
}


.internorga-form {
  .buttons {
    text-align: right;
  }
  textarea {
    height:77px;
  }
}

.form-thankyou-content {
  text-align:center;
  margin:0 auto;
  max-width:450px;
}
