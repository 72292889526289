.list-load-more {
  .button-load-more, .button-close {
    margin-top:40px;
    margin-bottom:20px;
    svg {
      position:relative;
      top:2px;
    }
  }
  .js & .wrap-further {
    height:0;
    overflow:hidden;
    :first-child {
      margin-top:0px;
    }
  }
  .button-close {
    display:none;
    svg {
      transform:rotate(180deg);
    }
  }
}

.link-list-item {
    position:relative;
    padding-right:30px;
    transition: opacity .2s;    
    /*padding-top:20px;*/
    margin-bottom: 20px;
    margin-top: 20px !important;
    // border-bottom:0;
    display:block;
    @include clearfix;
    p {
      margin-top:0;
      margin-bottom:0;
    }
    .small {
      text-transform: uppercase;
      font-family:$lora;
      font-size:12px;
      letter-spacing: 0.2em;
      font-weight:bold;
      color:$sander_gold_dunkel;
      &.inline {
        width:100%;
        float:left;
      }      
    }
    .title {
      line-height:31px;
      font-size:26px;
      font-weight:bold;
      font-family:$lato;
      color:$grau_62;
      margin-bottom: 10px;
      &.inline {
        float:left;
        max-width:calc(100% - 100px);
        padding-right:20px;
      }
    }
      .icon-jetzt-neu {
        display:inline-block;
        width:50px;
        height:50px;
        position:absolute;
        top: 0; 
        bottom: 0; 
        right: 40px;
        margin: auto;
      }

    .list-btn {
      display:inline-block;
      width:30px;
      height:50px;
      position:absolute;
      right:0;
      bottom:0;
      border-bottom:0;      
      .icon.icon-list-download, .icon.icon-new-page {
        width:25px;
        height:35px;
        margin-bottom:5px;
      }
    }

    
    @include gridle_state(tablet) {
      .title {
        font-size:21px;
        line-height:28px;
      }
    }

    @include gridle_state(phone) {
      .icon-jetzt-neu {
        display:none;
      }

      .info > br {
        // display:none;
      }

      .title.inline {
        max-width:100%;
        padding-right:0;
        float:none;
      }
    }

}


.large-link-list {
  margin-top:40px;
    &:first-child {
      margin-top:0;
    }
  a.link-list-item {
      font-style:normal;
      font-weight:normal;
      border-bottom:1px solid $grau_62;

      &:hover {
        opacity:0.7;
        border-bottom:1px solid $sander_gold_dunkel;
      }
  }  

  @include gridle_state(tablet) {
    margin-top:0;
  }
}



.download-links {
      background: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%2023.4%2033%22%20xml%3Aspace%3D%22preserve%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M0%200h23.4v33H0z%22%2F%3E%3C%2Fdefs%3E%3CclipPath%20id%3D%22b%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20overflow%3D%22visible%22%2F%3E%3C%2FclipPath%3E%3Cpath%20d%3D%22M12.2%200c.3.7.9%201.5.9%202.2.1%205.8%200%2011.5%200%2017.3v1.6c.5-.4.8-.6%201-.9l6.4-6.4c.7-.7%201.5-.9%202.3-.2.7.7.6%201.6-.1%202.3l-9.9%209.9c-.8.8-1.7.8-2.5-.1L.6%2016c-.7-.7-1-1.6-.2-2.4.9-.6%201.7-.3%202.5.4L9%2020.1c.3.3.6.6%201.1%201%200-.6.1-1%20.1-1.4V2.4c0-.8.5-1.6.8-2.4h1.2z%22%20clip-path%3D%22url(%23b)%22%20fill%3D%22%23BD9540%22%2F%3E%3Cpath%20fill%3D%22%23BD9540%22%20d%3D%22M.1%2029.2h23.2V33H.1z%22%2F%3E%3C%2Fsvg%3E") center right no-repeat;
      background-size: 25px 35px;
  }  
.subpages-links {
      background: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%2022.9%2030.4%22%20xml%3Aspace%3D%22preserve%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M0%200h22.9v30.4H0z%22%2F%3E%3C%2Fdefs%3E%3CclipPath%20id%3D%22b%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20overflow%3D%22visible%22%2F%3E%3C%2FclipPath%3E%3Cpath%20clip-path%3D%22url(%23b)%22%20fill%3D%22none%22%20stroke%3D%22%23BD9540%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20d%3D%22M7.4%2028.9l13.4-13.7L7.4%201.5%22%2F%3E%3Cpath%20clip-path%3D%22url(%23b)%22%20fill%3D%22none%22%20stroke%3D%22%23BD9540%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20d%3D%22M1.5%2024.1l8.6-8.9-8.6-8.9%22%2F%3E%3C%2Fsvg%3E") center right no-repeat;
      background-size: 25px 35px;
  }  
