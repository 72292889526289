/* ==========================================================================
   Site header and desktop/mobile navigation
   ========================================================================== */
body > .overlay {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  background-color:rgba($grau_51, 0.9);
  z-index: 10;
  visibility:hidden;
}

.site-header {

  .header-main {
    position:relative;
    margin-top:60px;
    background-color:$grau_bkg;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50% 50%;
    border-bottom:2px solid $sander_gold_dunkel;

    .p-home & {
      border-bottom:none;
      height:auto;
    }
  }

  .btn-school-lunch {
    float:right;
    background:$sander_gold_hell;
    height:60px;
    width:60px;
    display:flex;
    text-align: center;
    position:absolute;
    top:0;
    right:0;
    text-decoration: none;
    align-items: center;
    font-size:11px;
    line-height:11px;
    color:white;
    flex-direction:column;
    justify-content:center;
    transition:background .1s;
    .icon.icon-apple-book {
      width:20px;
      height:20px;
      background-size:20px 20px;
      margin-bottom:3px;
    }
    .descr {
      display:block;
      width:100%;
    }

    &:hover {
      background:$sander_gold_dunkel;
    }
  }

  .header-top {
    position: fixed;
    top: 0;
    width: 100%;
    margin:0;
    padding:0;
    z-index: 10;
    transform:translateZ(0);
    transition:transform .4s;
    -webkit-backface-visibility: hidden;

    .upper {
      background:#FFF;
      position: relative;
      z-index: 2;
    }

    .lower {
      background:rgba(85,85,85,0.8);
      position:relative;
      z-index: 1;
    }
  }

  .navigation {
    margin-left:-10px;
    margin-right:-10px;

    display:flex;
    flex-wrap:wrap;

    ul {
      list-style:none;
      margin:10px 0;
      padding:0;
      padding-left:175px;
      white-space: nowrap;
    }

    li {
      display:inline-block;
      margin: 0 10px;
    }

    a {
      font-size:11px;
      text-transform: uppercase;
      letter-spacing: 0.160em;
      text-decoration: none;
      line-height:40px;
      color:#000;
      padding-bottom:3px;
      border-bottom:1px solid transparent;
      transition: border-bottom .2s;

      &:hover, &.active {
        border-bottom:1px solid #000;
      }
    }

    ul.pages a {
      font-weight:700;
    }

    .sections {
      margin:0;
    }

    .sections a {
      color:white;

      &:hover, &.active {
        border-bottom:1px solid white;
      }
    }

    ul.pages-special {
      padding-left:30px;
      padding-right:60px;
      font-weight:bold;
      white-space:nowrap;
      position:absolute;
      right:60px;
      li {margin:0;}
    }
    .item-icon {
      position:relative;
      a {
        white-space: nowrap;
        display:block;
      }
      .svgicon {
        width:40px;
        height:40px;
      }
      &.shop {
        .svgicon svg {
          fill: $sander_gold_dunkel;
        }
        a:hover {
          border-color:$sander_gold_hell;
        }
      }
    }
  }

  .container-wide {
    margin:0 auto;
    padding:0 25px;
    max-width:1380px;
  }

  .logo-container {
    position:absolute;
    background:white;
    font-size:0;
    top:25px;
    line-height:0;
    z-index:2;
    user-select:none;
    box-shadow: 2px 4px 10px rgba(0,0,0,0.2);
    transition:transform .4s;
    .logo {
      width:125px;
      height:125px;
      margin:5px;
      -webkit-user-drag: none;
    }
  }

  .current-position-label {
    color:#FFF;
    background:$sander_gold_hell;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-size:12px;
    padding-left:20px;
    padding-top:10px;
    padding-bottom:10px;
    display:none;
    transform:translateY(-100%);
    transition: transform .4s, opacity .4s;
    opacity:0;
    > span {
      display:block;
    }
    @include gridle-state('phone') {
      display:block;
    }
  }

  .header-main {
    height:600px;
    position:relative;

    @include gridle_state('tablet') {
      height:500px;
    }

    @include gridle_state('phone') {
      height:180px;
    }
  }

  .container.container-fixed {
    position:fixed;
    left:50%;
    width:100%;
    transform:translateX(-50%);
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background:white;
    width:320px;
    height:100%;
    z-index: 11;
    box-shadow: 2px 4px 7px rgba(0,0,0,0.1);
    transform:translateX(-100%);
    transition: transform .5s;
    display:none;
    -webkit-backface-visibility: hidden;

    .sidebar-back {
      display:inline-block;
      margin-bottom:0;
      margin-left:0;
      color:$sander_gold_dunkel;
      text-decoration:none;
      font-size:22px;
      line-height:60px;
      margin-left:0;
      padding-left:20px;
      height:60px;
    }

    .sidebar-back-outer {
      width:40px;
      height:60px;
      right:0;
      margin:0;
      position:absolute;
      vertical-align: middle;
      opacity:0;
      transition: opacity .4s, right .4s;
      background:#dfdfdf;
      .icon {
        background-size:25px 25px;
        background-position:50% 50%;
        height:inherit;
        width:inherit;
      }
    }
  }

  &.is-sidebar-open .sidebar {
      transform:translateY(0%);
      .sidebar-back-outer {
        right:-40px;
        opacity:1;
      }
  }

  .sidebar-nav {
    margin-top:20px;
    display:block;
    position:absolute;
    top:40px;
    bottom:0;
    left:0;
    right:0;
    overflow-y:auto;
    overflow-x:hidden;

    ul {
      list-style:none;
      margin:0;
      padding:0;
    }

    .sections {
      display:none;
      margin-top:10px;
      margin-bottom:20px;
      > li {
        margin-bottom:0;
        > a {
        font-size:16px;
        line-height:40px;
        padding-left:20px;
        margin-bottom:0;
        text-decoration: none;
        color:black;
        &:active, &:focus, &.active {
          color:$sander_gold_dunkel;
        }
       }
      }
    }

    .pages {
      margin-top:20px;
    }

    .pages-special {
      background:$grau_951;
      margin-top:10px;
      padding-bottom:5px;
      > li {
        padding-right:0;
        .item-title {
          padding-left:15px;
        }
      }
    }

    > ul > li {
      margin-bottom:0;
      line-height:50px;
      position:relative;
      padding-right:50px;

      .item-title {
        display:flex;
        align-items: center;
        text-decoration:none;
        color:black;
        text-transform: uppercase;
        letter-spacing: 0.160em;
        font-weight:600;
        vertical-align:-2px;
        padding:15px 0 15px 20px;
        vertical-align: middle;
        margin-bottom:0;
        &.active {
          font-weight:800;
        }
        .icon-svg {
          fill:$sander_gold_dunkel;
        }
        > span {
          position:relative;
          top:2px;
        }
        .svg-icon-wrap {
          display:block;
          width:32px;
          top:auto;
          flex-shrink:0;
          top:3px;
          margin-right:10px;
          text-align:center;
        }
        .title {
          position:relative;
          padding-right:30px;
        }
      }

      .btn-expand-toggle {
        display:block;
        width:50px;
        height:50px;
        margin:0;
        padding:0;
        position:absolute;
        right:0;
        top:0;
        background-image:url($uri-icon-nav-plus);
        background-size:15px 15px;
        background-position: 50% 50%;
        background-repeat:no-repeat;
      }
      &.expanded {
        .sections {
          display: block;
        }
        .btn-expand-toggle {
          background-image:url($uri-icon-nav-minus);
        }
      }
    }
  }

  .sidebar-expand-btn {
    &:active {
      opacity:0.3;
    }
  }

  .sidebar-expand-btn-container {
    display:none;
  }

  @include gridle_state(desktop-xs) {

    .header-main {
      margin-top:60px;
    }

    .navigation ul.pages li, .navigation ul.sections li {
      margin:0 5px;
      a {
        letter-spacing: 0.120em;
      }
    }


    .navigation ul {
      margin:10px 0;
      padding-left:150px;
    }

    .navigation ul.pages-special {
      // margin: 0 10px;
      padding-left:10px;
      padding-right:10px;
    }

    .logo-container {
      top:15px;
      .logo {
        width:110px;
        height:110px;
        margin:3px;
      }
    }
  }

  @include gridle_state(tablet) {
    .sidebar {
      display:block;
    }
    .upper {
      box-shadow: 2px 4px 7px rgba(0,0,0,0.1);
    }
    .header-main {
      margin-top:60px;
    }
    .navigation {
      flex-wrap:nowrap;
      .pages {
        display:none;
      }
      ul.pages-special {
        margin:0 20px 0 0;
        height:60px;
        width:100%;
        padding-right:40px;
        display:flex;
        justify-content: flex-end;
        position:relative;
        right:auto;
      }
      .sidebar-expand-btn-container {
        height:40px;
        display:block;
        margin:10px 10px 10px 20px;
        .icon {
          display:inline-block;
          vertical-align: middle;
        }
        a {
          border:none;
        }
      }
      .item-icon, .item-icon.shop {
        margin:0;
        a {
          display:block;
          height:auto;
          padding-bottom:0;
          line-height:0;
          margin:0;
          border:none;
          .svgicon {
            width:60px;
            height:60px;
            svg {
              width: 24px;
              height: 24px;
            }
          }
          &:hover {
            border:none;
            opacity:0.6;
          }
        }
      }

      .item-icon.jobportal {
        margin:0;
        a {
          display:block;
          height:auto;
          padding-bottom:0;
          line-height:0;
          margin:0;
          border:none;
          .svgicon {
            width:60px;
            height:60px;
            svg {
              width: 40px;
              height: 40px;
            }
          }
          &:hover {
            border:none;
            opacity:0.6;
          }
        }
      }

    }
    .logo-container {
      left:50%;
      transform:translateX(-50%);
      .logo {
        width:80px;
        height:80px;
        margin:3px;
      }
    }
    .lower .navigation {
      display:none;
    }
  }

  @include gridle_state(phone) {
    .btn-school-lunch {
      display:none;
    }
    .logo-container {
      top:12px;
      .logo {
        width:65px;
        height:65px;
        margin:2px;
      }
    }
    .navigation {
      .sidebar-expand-btn-container {
        margin-left:0;
      }
      ul.pages-special {
        padding:0;
        margin:0;
        .item-icon.jobportal {
          display:none;
        }
        .item-icon {
          width:44px;
          .icon {
            background-size:26px 26px;
          }
        }
        .item-icon.contact svg {
          fill: $sander_gold_dunkel;
        }
        .item-icon.jobportal svg {
          fill: $sander_gold_dunkel;
          width: 40px;
          height: 40px;
        }
      }
    }

    .header-top.with-position-label {
      .logo-container {
        transform:translateX(-50%) translateY(-18px) scale(0.7);
      }
      .current-position-label {
        box-shadow: 0px 2px 3px rgba(0,0,0,0.4);
        transform:translateY(0);
        opacity:1;
      }
    }

    .header-top.header-hidden {
      .logo-container {
        transform:translateX(-50%) translateY(-18px) scale(0.7);
      }
      transform:translateY(-60px);
    }
  }
}

/* Aktionswochen & Landingpage (goldener Streifen)
   ========================================================================== */

.aktionswochen, .landingpage {
  .header-top .upper {
    background: $sander_gold_hell;
    padding-top:30px;
    padding-bottom:30px;
  }
  .header-top p {
    color:#FFF;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-weight:900;
    text-align:right;
    font-size:26px;
    margin:0;
  }

  @include gridle-state(tablet) {
    .header-top .upper {
      min-height:40px;
    }
    .header-top p {
      font-size:18px;
      text-align:left;
      max-width:40%;
    }
    .header-top .upper {
      padding-top:20px;
      padding-bottom:20px;
    }
  }

  @include gridle_state(phone) {
    .header-top p {
      font-size:14px;
      max-width:40%;
    }
    .header-top .upper {
      padding-top:15px;
      padding-bottom:15px;
      min-height: auto;
    }
  }
}

/* Internorga Landingpage
   ========================================================================== */

.page-title.page-title-landingpage {
  .flex-row {
    margin-top:20px;
    display: flex;
  }
  .left {
    flex-basis:115px;
  }
  .right {
    padding-left:30px;
    flex:1;
  }

  h1 {
    margin-top:0;
    min-width:initial;
  }

  @include gridle_state(phone) {
    .left {
      display:none;
    }
  }
}
