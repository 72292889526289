/* ==========================================================================
   Site footer
   ========================================================================== */

body > footer {
  padding:60px 0 140px 0;
  background:$grau_84;
  overflow:hidden;

  a, p {
    font-size:13px;
    font-weight:400;
  }

  .title {
    font-family:'Lora';
    text-transform: uppercase;
    font-size:11px;
    letter-spacing:0.380em;
    line-height:20px;
    margin-bottom:40px;

    &.center {
      text-align:center;
    }
  }

  ul {
    list-style:none;
    padding-left:0;
  }

  li {
    margin-bottom:0;
  }

  a {
    color:#000;
    text-decoration:none;
    &:hover {
      color:#666;
    }
  }

  .copyright-notice, .impressum-link {
    color:#666;
    @include gridle(10 push 1);

    @include gridle_state('tablet') {
        text-align:right;
        @include gridle(12 push 0);
    }
    @include gridle_state('phone') {
      text-align:center;
    }
  }

  .footerlinks {

    summary::-webkit-details-marker {
      display:none;
    }
    summary:first-of-type {
      list-style:none;
    }

    &.news, &.shop, &.helpful {
      @include gridle(2 push 1);
    }

    &.certificates {
      @include gridle(3 push 2);
    }

    @include gridle_state('tablet') {
      &.news {
        order:1;
        @include gridle (4 push 0);
      }

      &.shop {
        order:2;
        @include gridle (4 push 0);
      }

      &.certificates {
        @include gridle (4 push 0);
        order:3;
      }

      &.helpful {
        @include gridle(12 push 0);
        order:4;
      }

    }

    @include gridle_state('phone') {
      margin-bottom:20px;
      .title {
        margin-bottom:20px;
      }
      &.news, &.order, &.shop, &.certificates, &.helpful {
        order:initial;
        @include gridle(12);
        text-align:center;
      }
      .title {
        font-size:17px;
      }
      > ul > li a {
        font-size:14px;
        line-height:18px;
        }
        summary {
          user-select:none;
          cursor:pointer;
            &:after{
              content:'';
              display:inline-block;
              width:20px;
              height:20px;
              background-image:url($uri-icon-small-inline-arrow-down);
              background-position:50% 50%;
              background-repeat:no-repeat;
              top:3px;
              position:relative;
            }
        }

        &[open] {
          summary {
            &:after{
              background-image:url($uri-icon-small-inline-arrow-up);
            }
          }
        }
    }

  }

  @include gridle_state('tablet') {
    padding-bottom:60px;
  }
}

/* Aktionswochen & Internorga Landingpage
   ========================================================================== */

.aktionswochen, .landingpage {

  > footer {
    background:$sander_gold_hell;
  }

  .copyright-notice, .impressum-link {
    color:#FFF;
    margin-bottom:1em;
  }

  .impressum-link a {
    color: #FFF;

    border-bottom: 1px solid white;
    padding-bottom:3px;
    transition: border-bottom .1s, opacity .1s;

    &:hover {
      border-bottom: 1px solid transparent;
      opacity: .8;
    }
  }

}