.big-download img {
  box-shadow:8px 8px 20px rgba(0,0,0,0.4);
}
.download-button-group {
  margin-top:40px;
  white-space: nowrap;

  .btn-view {
    margin-right:10px;
  }

  @include gridle-state(tablet) {
    margin-top:20px;
    white-space: normal;
    margin-bottom:40px;
  }

}
