
/* Page teaser group
   ========================================================================== */
.page-teaser-group {
  margin-top:60px;
  margin-bottom:20px;

  .title-container {
    @include gridle(12);
  }

  .teaser-group-title {
    text-transform: uppercase;
    letter-spacing:0.230em;
    text-align:center;
    font-family:'Lora';
    font-weight:bold;
    font-size:18px;
    line-height:20px;
    padding:20px 0 20px 0;
    background:white;
    color:$sander-gold-dunkel;
    margin-bottom:20px;

    @include gridle_state('phone') {
      margin-bottom:0;
    }
  }
}

/* Page teaser
   ========================================================================== */
.page-teaser {
  @include gridle(4);
  transform: translateZ(0);
  text-decoration: none;

  .page-teaser-inner, .teaser-inner {
    background-color:white;
    background-size:cover;
    background-position:50% 50%;
    @include clearfix();
    position:relative;
    overflow:hidden;

  .teaser-background {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    margin:auto;
    min-width:50%;
    min-height:50%;
    transition: transform .4s;
    transform-origin:50% 50%;
    pointer-events:none;
  }


    .teaser-content-wrapper {
      height:160px;
      position: relative;
      display: table-cell;
      vertical-align: bottom;
      padding:100px 50px 40px 50px;
      text-decoration: none;
    }

    .teaser-headline {
      font-size:36px;
      line-height:34px;
      margin-bottom:40px;
      margin-top:0;
      flex-basis:100%;
    }

    &.dark {
      background-color:$grau_51;
      .teaser-headline {
        color:white;
        // letter-spacing:0.050em;
      }
    }
  }

  &:hover {
    .teaser-inner, .page-teaser-inner {
       .teaser-background {
          transform:translate(-50%, -50%) scale(1.05);
       }
    }
  }

  .icon.icon-shopping-cart {
    width:28px;
    height:28px;
    background-size:28px 28px;
  }

  @include gridle_state('tablet') {
    @include gridle(6);

    .page-teaser-inner {
      display:table;
      width:100%;
    }

    .teaser-inner, .page-teaser-inner {
      .teaser-background {
        display:none;
      }
    }

    .page-teaser-inner .teaser-content-wrapper {
      height:120px;
      padding:60px 20px 20px 20px;
    }

    &.wide {
      margin-top:20px;
      @include gridle(12);
      .page-teaser-inner {
        .teaser-content-wrapper {
          height:120px;
          padding:50px 20px 40px 20px;
          width:100%;
        }
        .teaser-headline {
          float:left;
          margin:10px 0 0 0;
        }
        .button {
          position:absolute;
          left:50%;
          margin-left:30px;
        }
      }
    }
  }

  @include gridle_state('phone') {
      @include gridle(12);
      margin-top:20px;
      .page-teaser-inner > .teaser-content-wrapper, &.wide .page-teaser-inner > .teaser-content-wrapper {
        padding:60px 20px 20px 20px;
        .teaser-headline {
          margin-bottom:20px;
          float:none;
        }
        .button {
          position:relative;
          left:auto;
          margin-left:0;
        }
      }

  }
}
