/* Home slider
   ========================================================================== */
.mod-home-slider {

  overflow:hidden;

  .background-slide-wrapper {
    max-height:520px;
    overflow:hidden;
  }

  .background-slide-container {
    padding-bottom: (570/1920) * 100%;
    box-sizing: border-box;
    position:relative;

    .slide {
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      background-size:cover;
      background-position:50% 50%;

      img {
        position:absolute;
        transform:translate(-50%, -50%);
      }
    }

    .btn-slide {
      position:absolute;
      height:100%;
      width:70px;
      z-index: 7;
      opacity:0.5;
      transition: opacity .5s;

      &:hover {
        opacity:1;
      }

      svg {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        fill:#FFF;
        transition:transform .2s;
        overflow:visible;
      }
    }

    .btn-slide-right {
      right:0;
      background:linear-gradient(to right, rgba(black,0) 0%, rgba(black, 0.4) 100%);

      &:hover {
        svg {
          transform:translate(-30%, -50%);
        }
      }
    }

    .btn-slide-left {
      left:0;
      background:linear-gradient(to left, rgba(black,0) 0%, rgba(black, 0.4) 100%);

      &:hover {
        svg {
          transform:translate(-70%, -50%);
        }
      }
    }

    .icon.icon-badge-new-home {
      width:139px;
      height:139px;
      position: absolute;
      right:4%;
      top:7%;
    }

    .icon.icon-video-frische-manufaktur {
      width:200px;
      height:139px;
      position: absolute;
      right:5%;
      top:43%;
    }

    .icon.icon-e-shop-button {
      width:200px;
      height:139px;
      position: absolute;
      right:5%;
      top:43%;
    }

    .icon.icon-video-frische-manufaktur-produkte {
      width:200px;
      height:139px;
      position: absolute;
      right:5%;
      top:43%;
    }

    .icon.icon-video-alles-aus-einer-hand {
      width:200px;
      height:139px;
      position: absolute;
      right:5%;
      top:43%;
    }

  }

  .container {
    position:relative;
    box-sizing:content-box;
    padding:0 60px;
  }

  .slider-main {
    position:relative;
    display:flex;
    height:120px;
    @include clearfix;

    .slider-right {
      flex: 1;
    }
    .slider-left {
      display:flex;
      width:450px;
    }
  }

  .small-title-headline {
    position: relative;
    top:0;
    font-size:12px;
    font-weight:bold;
    margin-bottom:10px;
  }

  .page-title {
    width: inherit;
    margin-left:0;
    left:auto;
    right:auto;
    bottom:0;
    padding-bottom:0;
    border-bottom: 3px solid $sander_gold_hell;
    z-index: 8;

    .inner {
      padding: 0 60px;
    }

    li {
      margin-bottom:3px;
    }

    ul {
      margin-top:0;
      width:340px;
    }

    .emotion-subhead {
      margin-top:0;
      margin-bottom:5px;
    }

    .btn-slide {
      display:none;
    }
  }

  .video-button-cta {
    position:absolute;
    text-align:right;
    right:30px;
    top:-90px;
    transition: color .2s;
    > span {
      display:block;
      line-height:16px;
      margin-right:55px;
    }
    .line-1, .line-2 {
      font-family:$lato;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-style:normal;
      font-size:15px;
    }
    .line-2 {
      font-weight:800;
    }
    .line-3 {
      font-size:12px;
    }
    &:after {
      content:'';
      display:block;
      width:50px;
      height:50px;
      position:absolute;
      right:0;
      top:-4px;
      border-radius:50%;
      box-shadow:4px 4px 8px rgba(0,0,0,0.3);
      background-color:$sander_gold_dunkel;
      background-image:url($uri-icon-triangle-right);
      background-repeat: no-repeat;
      background-size:15px 22px;
      background-position:60% 50%;
      transition: background-color .2s;
    }
    &:hover {
      border-color: transparent;
      color:$sander_gold_hell;
      &:after {
        background-color:$sander_gold_hell;
      }
    }

    @include gridle_state(phone) {
      display:none;
    }
  }

  .slide-title {
    white-space:nowrap;
    span {
      display:block;
    }

    font-size:36px;
    line-height:37px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color:$sander_gold_dunkel;

    .line-1 {
      font-weight:900;
      color:$grau_51;
    }
    margin-bottom:5px;
  }

  .page-title-slide {
    position:absolute;
    top:-40px;
    left:0;
    right:0;
    // Dev
    opacity:0;
    &:first-child {
      opacity:1;
      position:relative;
    }

    .button {
      margin-bottom:20px;
    }
    // margin-bottom:-20px;

    &.action {
      .line-1 {
        color: $sander_gold_dunkel;
      }
      .emotion-subhead {
        color:black;
      }
    }
  }

  .slider-navigation {
    overflow:hidden;
    margin-bottom:125px;
    border-bottom: 3px solid $sander_gold_hell;
    background:white;
  }

  .slider-rail {
    position:relative;
    font-size:0;
    white-space: nowrap;
    height:80px;

    .slide-select-item {
      height:inherit;
      overflow:hidden;
      width:25.05%;
      display:inline-block;
      position:relative;
      border-left:1px solid $grau_84;
      box-sizing:border-box;
      // background:$sander_gold_dunkel;
      opacity:1;
      cursor:pointer;

      img {
        opacity:0.7;
        transition: opacity .4s;
        min-width:100%;
      }

      &:after {
        content:'';
        display:block;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        // background:#FFF;
        // background:linear-gradient(to bottom,rgba( white,0),rgba( black,1));
        opacity:0.55;
        transition: opacity .4s;
        transform:translateZ(1px);
      }

      .title {
        color:$sander_gold_dunkel;
        text-transform: uppercase;
        display:block;
        font-size:11px;
        line-height:14px;
        letter-spacing: 0.160em;
        font-weight:bold;
        position:absolute;
        top:50%;
        left:0;
        width:100%;
        transform:translateY(-50%);
        text-align: center;
        z-index: 10;

        .subtitle {
          font-family:$lora;
          text-transform: none;
          font-style: italic;
          font-weight: normal;
          letter-spacing: normal;
          font-size:12px;
        }
      }

      &:hover {
        &:after {
          opacity:0;
        }
        img {
          opacity:1;
        }
        background:lighten($sander_gold_hell, 30%);
        transition: background .2s;
      }

      &.action {
        .title {
          color:white;
        }
        background:$sander_gold_hell;
      }
    }
  }

  @include gridle_state(desktop-m) {
    .background-slide-container {
      height:400px;
      padding-bottom:0;
      .slide .vector-type {
        transform:translate(-50%, -50%) scale(0.65);
      }
    }
    .page-title {
      .inner {
        margin-top:0;
      }
    }
  }

  @include gridle_state(desktop-s) {
    .container {
      padding: 0 0 0 60px;
    }
  }

  @include gridle_state('tablet') {
      .background-slide-container {
        padding-bottom:0;
        height:360px;
        .btn-slide {
          width:40px;
        }
      }
      .container {
        padding: 0 0 0 40px;
      }
      .slider-rail .slide-select-item {
        width: 1/3 * 100%;
      }
  }

  @include gridle_state(tablet-m) {
    .slider-rail .slide-select-item {
      width: 1/2 * 100%;
    }
    .background-slide-container {
      height:280px;
    }
    .page-title {
      margin-top:-60px;
      position: relative;
    }
    .slider-main {
      height:auto;
    }
  }

  @include gridle_state(tablet-s) {
    .container {
      padding: 0;
    }

    .background-slide-container {
      height:320px;
    }

    .background-slide-wrapper {
      position: relative;

      &:after {
        display:block;
        position: absolute;
        width:100%;
        bottom:-2px;
        right:0;
        left:0;
        height:200px;
        z-index: 8;
        pointer-events:none;
        background:linear-gradient(to bottom, rgba($grau_bkg, 0) 0,  rgba($grau_bkg, 1) 200px);
        content:'';
      }
    }

    .slider-rail .slide-select-item {
      $inactive: mix($grau_95, $sander_gold_hell, 60%);

      // width: 1/3 * 100%;
      width: 33.4%;
      border-left:none;
      background:rgba($grau_95, 0.6);
      border-bottom: 2px solid $inactive;
      transition:background .2s;

      &:hover{
        background:inherit;
      }

      .title {
        color: $inactive;
        transition: color .2s;
      }


      &.active {
        .title {
          color:$sander_gold_dunkel;
        }
        border-bottom: 2px solid $sander_gold_hell;
        background: rgba(white, 0.9);
      }

      &.action {
        background: rgba($sander_gold_hell, 0.4);
        .title {
          color: $sander_gold_dunkel;
        }
        &.active {
          background: $sander_gold_hell;
          .title {
            color: white;
          }
        }
      }
    }

    .page-title {
      border-bottom:none;
      max-width:490px;
      margin-top:-80px;

      .inner {
        padding:0 50px;
      }

      &:before {
        top:-139px;
      }

      ul {
        width:auto;
      }
    }

    .slider-main {
      height:auto;
      display:block;
      // padding:0 40px;

      .slider-left {
        width:100%;
        display:block;
        box-sizing: border-box;
        padding: 0 20px;
      }
      .slider-right {
        width:100%;
        display:block;
        position:relative;
        z-index: 9;
      }
    }

    .slider-navigation {
      border-bottom:none;
      margin-bottom:0;
    }
  }

  @include gridle_state(phone) {
    .background-slide-container {
      .btn-slide {
        display:none;
      }
      .icon.icon-badge-new-home {
        width:120px;
        height:120px;
        position: absolute;
        right:15px;
        top:15px;
      }
      .icon.icon-video-frische-manufaktur {
        width: 0px;
        height: 0px;
        position: absolute;
        right: 15px;
        top: 30px;
      }
      .icon.icon-e-shop-button {
        width: 0px;
        height: 0px;
        position: absolute;
        right: 15px;
        top: 30px;
      }
      .icon.icon-video-frische-manufaktur-produkte {
        width: 0px;
        height: 0px;
        position: absolute;
        right: 15px;
        top: 30px;
      }
      .icon.icon-alles-aus-einer-hand {
        width: 0px;
        height: 0px;
        position: absolute;
        right: 15px;
        top: 30px;
      }
    }
    .page-title {
       margin-top:-120px;
      .btn-slide {
        display:block;
        position:absolute;
        top:-35px;
        width:50px;
        height:50px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width:16px;
          height:16px;
          fill: $sander_gold_dunkel;
        }
        &, &:hover, &:active {
          border:none;
        }
      }
      .btn-slide-left {
        left:0;
      }
      .btn-slide-right {
        right:0;
      }
    }
    .page-title-slide {
      top: -50px;
      .button {
        margin-bottom:10px;
      }
    }
    .small-title-headline {
      margin-bottom:3px;
    }
  }

  @include gridle_state(phone-s) {
    .slide-title {
      font-size:30px;
      line-height:30px;
    }
    .page-title-slide {
      top: -60px;
    }
  }

  @include gridle_state(phone-xs) {
    .slide-title {
      font-size:26px;
      line-height:29px;
    }
    .slider-rail {
      height:60px;
      .slide-select-item {
        width:150px;
      }
    }
  }

  @include gridle_state(phone-xxs) {
    .page-title {
      margin-top: -140px;

      .btn-slide {
        display:none;
      }
      .inner {
        padding: 0 20px;
      }
    }
    .page-title-slide {
      top: -75px;
      margin-bottom:-37px;
    }
  }
}
