.lightbox-overlay {
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:rgba(255,255,255,0.9);
  z-index: 10;
  cursor:pointer;

  .close {
    display:block;
    width:30px;
    height:30px;
    position: absolute;
    right:20px;
    top:20px;

    svg {
      fill: $sander_gold_dunkel;
      transition: fill .1s;
    }

    &:hover {
      svg {
        fill: $sander_gold_hell;
      }
    }
  }
}

.lightbox-container {
  position:fixed;
  top:40px;
  left:40px;
  right:40px;
  bottom:40px;
  display:flex;
  z-index: 11;
  pointer-events: none;
  // display:flex;
  align-items: center;
  justify-content: center;

  video {
    cursor:default;
    max-width:100%;
    max-height:100%;
    z-index: 11;
    box-shadow:0 0 80px rgba(#8e7e64,.4);
    pointer-events: all;
    flex:initial;
  }

  @include gridle_state(desktop-s) {
    video {
      flex:1;
    }
  }
}
