/* Card List
   ========================================================================== */

.card-list {

  .card {
    @include gridle(5/10);
    .inner {
      box-shadow:7px 7px 10px rgba(0,0,0,0.3 );
      padding:40px;
    }
  }
  
  .event-card {
    margin-top:20px;
    @include gridle(1/3);
    h3 {
      margin-bottom:20px;
    }
    .inner {
      background:white;      
      padding:40px;      
    }
  }

  ul {
    margin-top:0;
  }

  h3 {
    font-size:26px;
    line-height:31px;
    text-transform: none;
    letter-spacing: normal;
    color:$grau_51;
    border-bottom:0;
    margin-top:0;
  }

  li {
    margin-bottom:0;
  }

  .date {
    font-size:15px;
    line-height:18px;
    margin-bottom:20px;
    font-family:$lora;
    font-weight:bold;
    color:$sander_gold_dunkel;
    text-transform: uppercase;
    letter-spacing:0.1em;
    display:block;
  }

  .load-more-btn-container {
    text-align:center;
    padding-top:40px;
    padding-bottom:0;
  }

  @include gridle_state(desktop-s) {
    .card {
      @include gridle(1/2);
      margin-top:20px;
    }
  }
  @include gridle_state(tablet) {
    .card {
      @include gridle(12);
      margin-top:20px;
    }
  }

  @include gridle_state(phone) {
    .card {
      .inner {
        padding:20px;
      }
    }
    h3 {
      font-size:19px;
      line-height:21px;
    }
  }
}
