// bower:scss
// endbower

/* ==========================================================================
   Variables and Mixins
   ========================================================================== */

// Colors
$sander_gold_dunkel:#bd9540;
$sander_gold_hell:#cbac5e;
$sander_braun:#81530f;
$sander_gruen:#6d7809;
$sander_gruen_20:mix(rgb(110,120,10),#FFF, 20%);
$grau_51:#333333;
$grau_62:#555555;
$grau_73:#aaaaaa;
$grau_84:#d2d2d2;
$grau_95:#e8e8e8;
$grau_951:#f3f3f3;
$grau_bkg: #e3e3e3;

// String replacement
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// SVG Data URL generation
@function urify($string) {

  $string: str-replace($string, '#', '%23');
  $string: str-replace($string, '<', '%3C');
  $string: str-replace($string, '>', '%3E');
  $string: str-replace($string, '=', '%3D');
  $string: str-replace($string, ' ', '%20');
  $string: str-replace($string, '"', '%22');
  $string: 'data:image/svg+xml,' + $string;
  @return $string;

}


@mixin clearfix() {
  &::before, &::after {
    content:"";
    display:table;
  }
  &::after {
      clear:both;
  }
  & {
      zoom:1; /* For IE 6/7 (trigger hasLayout) */
  }
}

/* ==========================================================================
   Cookie Consent
   ========================================================================== */
@import '../bower_components/cookieconsent/src/styles/base';
@import '../bower_components/cookieconsent/src/styles/animation';
@import '../bower_components/cookieconsent/src/styles/layout';
@import '../bower_components/cookieconsent/src/styles/media';

.cc-window {
  font-family:inherit;
  background:white;
  font-size:14px;
  color:#333;
  font-weight:normal;
}

.cc-window.cc-banner {
  // border-top:3px solid #bd9540;
  padding:0;
  .cc-message {
    padding:10px 20px;
  }
  .cc-btn.cc-dismiss {
    padding: 15px 40px;
    font-weight:normal;
  }
}

/* ==========================================================================
   Grid System setup
   ========================================================================== */

// Import gridle with flexbox support
@import 'gridle/sass/gridle/gridle-flex';

@include gridle_setup((
  context: 12,
  gutter-width: 20px
));

@include gridle_register_state(desktop-tablet-s,(
  min-width: 577px
));

@include gridle_register_state(desktop-only,(
  min-width: 1025px
));

@include gridle_register_state(desktop-m,(
  // min-width: 1025px,
  max-width: 1366px
));

@include gridle_register_state(desktop-s,(
  // min-width: 1025px,
  max-width: 1290px
));

@include gridle_register_state(desktop-xs,(
  // min-width: 1025px,
  max-width: 1170px
));

@include gridle_register_state(tablet,(
  // min-width: 577px,
  max-width: 1024px
));

@include gridle_register_state(tablet-m, (
  max-width: 863px
));

@include gridle_register_state(tablet-s,(
  // min-width: 577px,
  max-width: 767px
));


@include gridle_register_state(tablet-s-only,(
  min-width: 576px,
  max-width: 767px
));

@include gridle_register_state(phone,(
  max-width: 575px
));

// (374) - 479
@include gridle_register_state(phone-s, (
  max-width: 479px
));

// (360) - 374
@include gridle_register_state(phone-xs, (
  max-width: 374px
));

// (320) - 359
@include gridle_register_state(phone-xxs, (
  max-width: 359px
));

// Do not use gridle classes in production, they substantially increase the file size.
// @include gridle_generate_classes();
@include gridle_generate_json_settings();


.row {
  // @include gridle_row();

  flex-direction: row;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
}

.row-align-center {
  justify-content: center;
}

.row-align-middle {
  align-items: center;
}

.container {
  max-width: 1240px;
  margin:0 auto;
  box-sizing:content-box;
  padding: 0 40px;

  @include gridle_state('phone') {
    padding: 0 20px;
  }
}

/* ==========================================================================
   User agent related styles
   ========================================================================== */

html {
  height:100%;
}

html, body {
  // height:100%;
  // width:100%;
  min-width: 320px;
}

body {
  overflow-y: scroll;
  background: $grau_84;
}

$text_select_color: mix($sander_gold_dunkel,#FFF, 30%);

::-moz-selection { background:$text_select_color; }
::selection { background:$text_select_color; }

/* Dirty details pollyfill override */
html.no-details details > summary::before, html.no-details details[open] > summary::before {
  content:'';
  display:none;
  font-size:0;
  visibility: hidden;
}

/* ==========================================================================
   SVG Icons
   ========================================================================== */

body > svg {
  height:0;
  width:0;
  visibility: hidden;
  display:none;
}

svg path {
  fill:inherit;
  stroke:inherit;
}

.svgicon-centered {
  display:flex;
  justify-content: center;
  align-items: center;
}

@import '_icons.svg.scss';

/* ==========================================================================
   Transition
   ========================================================================== */



/* ==========================================================================
   Animations
   ========================================================================== */

@keyframes bg-flash {
  0% { background-color:rgba($grau_73, 0); }
  30% { background-color:rgba($grau_73, 0.3); }
  100% { background-color:rgba($grau_73, 0); }
}

.anim-bg-flash-active {
  animation-name: bg-flash;
  animation-iteration-count: 1;
  animation-duration: 0.75s;
}

/* ==========================================================================
   General Typography
   ========================================================================== */

$lora: 'Lora', 'Georgia', 'serif';
$lato: 'LatoLatinWeb', 'Calibri', sans-serif;

body {
  font-family: $lato;
}

ul {
  padding-left:20px;
}

p, li {
  color:$grau_51;
}

p, a, li {
  line-height:20px;
  font-size:14px;
  margin-top:0;
  margin-bottom:20px;

  &:last-child {
    margin-bottom:0;
  }

  @include gridle_state('tablet') {
    font-size:14px;
  }
}

li {
  margin-bottom:15px;
}

.page-section a, .page-title a, .section-single a {
  font-family:$lora;
  font-style:italic;
  padding-bottom:2px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  color:$sander_gold_dunkel;
  transition: border .2s;

  &:hover {
    border-bottom: 1px solid $sander_gold_hell;
  }
}

/* h2 */ .section-headline, .section-subheadline, .teaser-headline {
  font-size:32px;
  line-height:40px;
  text-transform: uppercase;
  letter-spacing:0.060em;
  position:relative;

  @include gridle_state('tablet') {
    font-size:26px;
    line-height:26px;
  }
}

.small-title-headline {
  text-transform: uppercase;
  font-family:Lora;
  font-size:12px;
  line-height:18px;
  color:$sander_gold_dunkel;
  letter-spacing:0.2em;
  display:block;
}

.section-headline, .teaser-headline {
  color:$grau_51;
  font-weight:900;
  top:-5px;
}

.section-subheadline {
  color:$sander_gold_dunkel;
  font-weight:normal;
  top:-10px;

  @include gridle_state('tablet') {
    top:-5px;
  }
}

.inner-headline {
  font-family:$lora;
  font-size:19px;
  line-height:24px;
  letter-spacing: 0.23em;
  font-weight:bold;
  text-transform: uppercase;
  color:$sander_gold_dunkel;
}

h3 {
  font-size:14px;
  line-height:20px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  color:$sander_gold_dunkel;
  display:inline-block;
  padding-bottom:2px;
  border-bottom:1.75px solid $sander_gold_hell;
  text-decoration: none;

  &.span-u {
    border-bottom:0;
    span {
      border-bottom:1.75px solid $sander_gold_hell;
    }
  }
}

.lora-contact-title {
  font-size:19px;
  text-transform: uppercase;
  font-family:$lora;
  letter-spacing: 0.23em;
  min-height:40px;
  color:$sander_gold_dunkel;
  font-weight:bold;
  margin-top:40px;
  margin-bottom:20px;
}

.hidden {
  visibility:hidden;
  display:none;
}

.quote {
  font-family:$lora;
  font-size:19px;
  line-height:27px;
}

.quote-author {
  font-size:14px;
  > p {
    color:$sander_gold_dunkel;
  }

  .author {
    text-transform: uppercase;
    margin-bottom:0;
    font-weight:bold;
    font-size:14px;
    letter-spacing: 0.08em;
  }
  .position {
    font-size:14px;
    line-height:20px;
  }
}

.adress-title {
  font-size:19px;
  font-family:$lora;
  color:$sander_gold_dunkel
}

/* ==========================================================================
   Header, footer and main content styles
  ========================================================================== */

// Import header, desktop and mobile navigation styles
@import 'partials/site-header-navigation';

.btn-backtotop {
  display:block;
  width:60px;
  height:60px;
  left:0;
  position:fixed;
  background-color:$grau_73;
  transition:bottom .4s, opacity .3s;
  margin-bottom:0;
  opacity:0;

  &:after {
    background-image:url($uri-icon-arrow-up-white);
    background-repeat: no-repeat;
    background-position:50% 50%;
    background-size: 20px auto;
    transition: opacity .5s;
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    transform:rotateZ(180deg);
    transition:transform .3s;
  }

  transform:translateZ(0);

  body.scrolled & {
    bottom:40px;
    opacity:1;

    &:after {
      transform:rotateZ(0deg);
    }
  }

  @include gridle_state('desktop-m') {
    width:40px;
    height:40px;
  }

  .p-home & {
    @include gridle_state('desktop-m') {
      width:60px;
      height:60px;
    }
    @include gridle_state('tablet') {
      width:40px;
      height:40px;
    }
  }

  @include gridle_state('tablet-s') {
    display:none;
  }
}

body > main {
  background-color:$grau_bkg;
  background-image:url('../images/background/wood-hell.jpg');
  background-position:0 100%;
  background-repeat:no-repeat;
  background-size:100% auto;
  @include clearfix;
  padding-top:40px;
  padding-bottom:80px;

  @include gridle_state('phone') {
    padding-bottom:0;
  }
}

body.default > main.content {
  margin-top:60px;
  padding-top:60px;
}

// Import footer styles
@import 'partials/footer';

/* ==========================================================================
   Buttons and links
  ========================================================================== */


/* Learn more link and button
   ========================================================================== */

.learnmore, .button {
  color:$sander_gold_dunkel;
  text-decoration: none;
  font-family:$lora;
  font-style:italic;
  padding-bottom:2px;
  border-bottom: 1px solid transparent;
  transition: border-color .2s;
  display:inline-block;
  white-space:nowrap;

  &.learnmore:before {
    content:'';
    position:relative;
    display:inline-block;
    background-image:url($uri-icon-small-arrow-down);
    background-position:50% 50%;
    background-repeat:no-repeat;
    width:14px;
    height:9px;
    margin-left:0;
    margin-right:2px;
    vertical-align: baseline;
  }
}

.learnmore:hover {
  border-bottom: 1px solid $sander_gold_hell;
}

.button.btn-gold {
  display:inline-block;
  background:$sander_gold_hell;
  color:white;
  padding:10px 40px;
  transition: background .1s;
  border:none;
  cursor:pointer;
  border-bottom:0;

  svg {
    fill:#FFF;
  }

  &:hover {
    background:$sander_gold_dunkel;
    border-bottom:0;
  }
}

/* Page link
   ========================================================================== */

   .link-page, .main-text a {
  color:$sander_gold_dunkel;
  text-decoration: none;
  font-family:'Lora';
  font-style:italic;
  padding-bottom:2px;
  border-bottom: 1px solid transparent;
  transition: border-color .2s;
  display:inline-block;

  &:before {
    content:'» ';
  }

  &:hover {
    border-bottom: 1px solid $sander_gold_hell;
  }
}

.without a {

  &:before {
    content:' ';
  }
}


/* ==========================================================================
   General Elements
   ========================================================================== */

/* Columns
   ========================================================================== */

.sc-columns, .sander-columns {
  margin-top:40px;
  columns:2;

  @include gridle_state(tablet) {
    columns:1 !important;
    -moz-columns:1 !important;
  }
}



/* Numbered List
   ========================================================================== */

ol {
  margin:0;
  padding:0;
  counter-reset: fact-counter;
  li {
    font-size:14px;
    line-height:20px;
    list-style:none;
    font-weight:bold;
    display:block;
    padding-left:40px;
    position:relative;
    margin:25px 0;
    color:$grau_51;

    &:before {
      content:counter(fact-counter);
      counter-increment:fact-counter;
      display:inline-block;
      line-height:30px;
      width:30px;
      height:30px;
      text-align:center;
      border-radius:50%;
      margin-right:5px;
      position:absolute;
      left:0;
      top:50%;
      margin-top:-16px;
      background-image:url($uri-icon-number-list-dot);
      color:white;
      font-family:'Lora';
      font-weight: 700;
    }
  }
}

.sc-columns ol > li {
  margin-top:10px;
  break-inside:avoid-column;
}


/* Infobox with list
   ========================================================================== */

.box-info-list {
  padding:30px 20px 25px 20px;
  background:rgba(white,0.85);
  border:1px solid $sander_gold_dunkel;
  position:relative;
  overflow:hidden;
  &.two ul {
    column-count:2;
  }


  h3 {
    margin-top:0;
  }

  ul {
    padding-left:0;
    list-style:none;
    margin-top:5px;
  }

  ul > li {
    color:$grau_62;
    // font-weight:bold;
    margin:4px 0 4px 10px;
    font-size:14px;
    line-height:20px;
    &:before {
      content:'\00B7';
      width:10px;
      display:inline-block;
      margin-left:-10px;
    }
  }

  .learnmore-container {
    height:100px;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background:linear-gradient(180deg, rgba(white, 0) 0%, rgba(white, 1) 60%, rgba(white, 1) 100%);
    .learnmore {
      position:absolute;
      font-size:14px;
      left:20px;
      bottom:20px;
    }
  }

  @include gridle_state('tablet') {
    &.two ul {
      column-count:3;
    }
    margin-top:20px;
  }

  @include gridle_state('phone') {
    &.two ul {
      column-count:1;
    }
  }
}


/* Infobox with double border
   ========================================================================== */

.box-info-doubleborder {
  background:rgba(white,0.85);
  border: 1px solid $sander_gold_dunkel;
  position:relative;
  text-align:center;
  padding: 40px 20px 40px 20px;

  &:after {
    content:'';
    display:block;
    position:absolute;
    top:8px;
    right:8px;
    bottom:8px;
    left:8px;
    border: 1px solid $sander_gold_dunkel;
  }

  img {
    max-width:280px;
    margin:0 auto;
    display:block;
  }

  @include gridle_state('tablet') {
    max-width:375px;
    margin:0 auto;
  }
}

/* Infobox with double border videoplay
   ========================================================================== */

   .box-info-doubleborder-video {
    position:relative;
    text-align:center;

    img {
      max-width:200px;
      margin:0 auto;
      display:block;
    }

    @include gridle_state('tablet') {
      max-width:375px;
      margin:0 auto;
    }
  }


/* Fact list
   ========================================================================== */

.checkmark-list {

  list-style:none;
  padding-left:0;

  li {
    padding-left:40px;
    position:relative;
  }

  li:before {
    content:'';
    display:inline-block;
    width:32px;
    height:32px;
    position:absolute;
    left:0;
    top:-15px;
    background-image:url($uri-icon-checkmark);
  }
}


/* Info block with icons
   ========================================================================== */

.icon-info-block {
  list-style:none;
  margin:40px 0 20px 0;
  position:relative;
  padding-left:50px;

  .title {
    text-transform: uppercase;
    color:$sander_gold_dunkel;
    letter-spacing:0.075em;
    margin-bottom:4px;
    position:relative;
  }

  li {
    margin-bottom:16px;
    > .icon {
      transform:none;
      left:0;
      top:0;
    }
  }

  .icon {
    position:absolute;
    left:-50px;
    top:50%;
    transform:translateY(-65%);
  }
}


/* Contact person and list
   ========================================================================== */
  .contact-person {
    margin-top:10px;
    margin-bottom:10px;

    // Hides the small arrow before the link
    a:before {
      display:none;
    }

    > .title {
      text-transform: uppercase;
      letter-spacing: 0.075em;
      color:$sander_gold_dunkel;
      margin-bottom:20px;
    }
  }

  .contact-name, .contact-person .name {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight:bold;
      font-size:13px;
    }

  .contact-list {
    border-top:1px solid $grau_62;
    padding-top:30px;
    padding-bottom:30px;

    .location {
      color:$sander_gold_dunkel;
      font-family:$lora;
      font-size:12px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-weight:bold;
      line-height:20px;
      width:20%;
    }

    > .row > div {
      font-size:14px;
      vertical-align: top;
      line-height:20px;
      padding-bottom:15px;
    }

    .info {
      text-align: right;
    }

    .location {
      @include gridle(3);
    }
    .plz-title {
      @include gridle(2);
    }
    .plz {
      @include gridle(2);
    }
    .col-contact-name {
      @include gridle(2);
    }
    .info {
      @include gridle(3);
    }

    @include gridle_state(tablet) {
      .location {
        @include gridle(12);
      }
      .plz {
        @include gridle(3);
      }
      .col-contact-name {
        @include gridle(3);
      }
      .info {
        @include gridle(4);
      }
    }

    @include gridle_state(tablet-s) {
      .plz-title {
        @include gridle(3);
      }
      .col-contact-name {
        @include gridle(6);
      }
      .info {
        @include gridle(12 push 6);
        text-align:left;
      }
    }

    @include gridle_state(phone) {
      .location,
      .plz-title,
      .plz,
      .col-contact-name,
      .info {
        @include gridle(12 push 0);
      }
    }
  }


/* Table with opening / speaking times
   ========================================================================== */

.times-table {
  border-spacing:0;
  th {
    font-size:13px;
    text-transform: uppercase;
    padding-right:5px;
    font-weight:bold;
  }
}



/* Section title centered
   ========================================================================== */
.section-title-centered {
  text-align:center;
  > div {
    @include gridle(6);
    @include gridle_state(tablet) {
      @include gridle(10);
    };
  }
}


/* Logo list
   ========================================================================== */
.logo-list {
  margin-top:40px;
  li {
    display:block;
    text-align:center;
    @include gridle(2/10);
    .title {
      font-weight:bold;
      margin-bottom:0;
    }
  }
  &.keyvisuals {
    li {
      @include gridle(1/3);
    }
    img {
      max-width:115px;
      margin-bottom:20px;
    }
  }
  &.service {
    margin-left:1/12*100%;
    margin-right:1/12*100%;
    img {
      max-width:115px;
      max-height:70px;
      margin-bottom:20px;
    }
    li {
      @include gridle(1/4);
      margin-top:20px;
    }
  }
  @include gridle_state(tablet) {
    &.service {
      margin-left:-10px;
      margin-right:-10px;
    }
    > li, &.service > li, &.keyvisuals > li {
      @include gridle(1/3);
    }
  }
  @include gridle_state(tablet-s) {
    margin-top:20px;
    > li, &.service > li, &.keyvisuals > li {
      @include gridle(1/2);
    }
  }
  @include gridle_state(phone) {
    > li, &.service > li, &.keyvisuals > li {
      @include gridle(12);
    }
  }
}



/* Year List
   ========================================================================== */
.date-list {
    margin-top:60px;

    .inner {
      margin-top:0;
    }

  ul {
    list-style:none;
    padding-left:0;
    margin-top:0;

    li {
      margin-top:0;
      margin-bottom:0;
      padding-bottom:20px;
      break-inside:avoid-column;
    }

    .year-number {
      float:left;
      display:block;
      width:60px;
      font-family:$lora;
      font-weight:bold;
      font-style:italic;
      color:$sander_gold_dunkel;
      font-size:17px;
      letter-spacing: 0.1em;
    }

    .year-content {
      margin-left:60px;
    }
  }

   @include gridle_state(tablet) {
     margin-top:40px;
   }

}


/* Linked logos
   ========================================================================== */

.linked-logos {
  list-style:none;
  padding-left:0;

  li {
    @include gridle(3);
    max-height:100px;
    margin-bottom:10px;
    margin-top:10px;
  }

  a {
    // display:flex;
    transition:opacity .2s;
  }

  img {
    max-height: 100%;
    margin:0 auto;
    display:block;
  }

  a:hover {
    opacity:0.7;
  }

  @include gridle_state(tablet) {
    li {
      @include gridle(4);
    }
  }
  @include gridle_state(tablet-s) {
    li {
      @include gridle(1/2);
    }
  }
}


/* Section content logos
   ========================================================================== */
.section-content-logos {
  margin-top:40px;
  .linked-logos li {
    max-height:75px;
    img {
      max-height:75px;
      max-width:100%;
    }
  }

  @include gridle_state(phone) {
    margin-top:0;
  }
}

/* Vertical Image Wrapper
   ========================================================================== */
.image-wrapper {
  position:relative;
  overflow: hidden;
  img {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
  }
}

/* Gewinnspiel Addresse
   ========================================================================== */

.gewinnspiel-address {
  font-size:13px;
  line-height:21px;
  font-weight: bold;
  color:$sander_gold_dunkel;
}


/* ==========================================================================
   Import modules
   ========================================================================== */

@import 'modules/page-section';
@import 'modules/page-teaser';
@import 'modules/tabview';
@import 'modules/footer-logo-slider';
@import 'modules/page-title';
@import 'modules/home-slider';
@import 'modules/teaser-detailed';
@import 'modules/teasers-simple-film';
@import 'modules/teaser-download';
@import 'modules/testimonial-slider';
@import 'modules/card-list';
@import 'modules/forms';
@import 'modules/large-link-list';
@import 'modules/section-single';
@import 'modules/section-job';
@import 'modules/big-download';
@import 'modules/video-lightbox';
@import 'modules/section-gewinnspiel-form';


/* ==========================================================================
   Individual Modifications
   ========================================================================== */

.box-manufaktur {

  img {
    max-width:280px;
    width:100%;
    margin:0 auto;
    display:block;
  }
}

.box-manufaktur-vid {

    img {
      max-width:280px;
      width:100%;
      margin:0 auto;
      display:block;
    }
  }

// For Unternehmensaufbau
.logo-box {
  height:470px;
  text-align: center;
  position:relative;
  margin:0 auto;
  font-size:14px;
  img {
    position:absolute;
    left:50%;
    transform:translateX(-50%);
  }
  .addr {
    position:absolute;
    top:150px;
    width:100%;
  }
  .g-left, .g-right {
    width:50%;
    position:absolute;
    top:350px;
    padding: 0 5px 0 5px;
    box-sizing: border-box;
  }
  .g-left {
    left:0;
  }
  .g-right {
    right:0;
  }

  @include gridle_state(phone) {
    // width:300px;
    background-image:url('../images/background/about-mobile.jpg');
    background-repeat:no-repeat;
    background-position: 50% 0;
    font-size:13px;
    width:270px;
    position:relative;
    left:50%;
    transform:translateX(-50%);
    margin:0;
    img {
      display:none;
    }
    .addr {
      top:160px;
      .adress-title {
        font-size:17px;
        margin-bottom:5px;
      }
    }
    .g-left, .g-right  {
      top:340px;
    }
  }
}

// Service Map

.service-map {
  margin-top:-70px;
  margin-bottom:60px;
  @include clearfix;
  img {
    height:400px;
    width:auto;
    float:right;
  }
  @include gridle_state(tablet) {
    // margin-top:0;
    display:none;
  }
}

.page-section.section_kundenservice {
  @include gridle_state(tablet) {
      > .row .col-remaining {
        display:none;
      }
  }
}

.side-logo {
  // width:100%;
  img {
    // max-width:100%;
    float:right;
  }
  @include gridle_state(tablet) {
    img {
      float:none;
      max-width:100%;
    }
  }
}

.mehrwerte-illustration {
  text-align:center;
  margin-top:40px;
  img {
    max-width:100%;
  }
  @include gridle_state(phone) {
    margin-top:0;
  }
}

.page-section.section_soziales-engagement > .row > .col-remaining {
  min-height:auto;
}

.page-section.section_qualitaetsmanagement .main-text {
  max-width:400px;
}

.partner {
  .image, .info {
    width: 46%;
    margin: 0 2%;
    float: left;
  }
  .image {
    img {
      width: 100%;
      height: auto;
    }
  }
  .contact-person {
    margin-top:0;
    margin-bottom:0;
  }
  @include gridle-state(desktop-s) {
    .image, .info {
      margin: 0 4%;
    }
    .image {
      width: 32%;
    }
    .info {
      width: 52%;
    }
  }
  @include gridle-state(tablet) {
    .image, .info {
      width: 22%;
    }
    .info {
      width: 62%;
    }
    margin-top:40px;
  }
  @include gridle_state(tablet-s) {
    .info {
      margin-top:20px;
    }
    .image {
      float:none;
    }
  }
}

.section_news {
  .large-link-list {
    margin-top:0;
  }
  .link-list-item:first-child {
    margin-top:0;
    padding-top:0;
  }
  .inner-headline {
    margin-bottom:20px;
  }
  .teaser-news-image {
    max-width:100%;
  }
  .teaser-news-content {
    margin-bottom:40px;
  }
}

.section_leistungsangebot {
  @include gridle_state(tablet) {
    .col-remaining {
      margin-top:40px;
      padding-bottom:0;
    }
  }
}

.section_anspruch {
    background-position: 90% 0%;
}

@include gridle-state(desktop-s) {
  .section_anforderungen {
      background-position: 30% 0%;
  }
  .section_beratung {
      background-position: 40% 0%;
  }
  .section_anspruch {
      background-position: 65% 0%;
  }
}
@include gridle-state(phone) {
  .section-beratung {
    .remaining-background {
      background-position: 20% 0%;
    }
  }
}

.section_qualitaetsmanagement {
  @include gridle_state(desktop-only) {
    .linked-logos li {
      max-width:20%;
    }
  }
}

@include gridle-state(tablet) {

.section_geschichte {
  background-image: none!important;
  .main-text {
    max-width: 100%!important;
  }
  h2 {
    max-width: 100%!important;
  }
}

.main-text-transparent, .section_soziales-engagement  {
  .main-text {
    max-width: 100%!important;
    h2 {
      max-width: 100%!important;
    }
  }
}

}


  @include gridle_state(phone) {
    .p-konzepte {
      .header-main {
        background-size: 600px;
        background-position: 100% 100%;
      }
    }
    .img-on-white {
      background-image: none!important;
      .main-text {
        max-width: 100%!important;
        h2 {
          max-width: 100%!important;
        }
      }
    }

  }



.section_ansprechpartner .partner  {
  @include gridle(6);
  @include gridle_state(tablet) {
    @include gridle(12);
  }
  @include gridle_state(tablet-s) {
    .image {
      width:auto;
      max-width:240px;
    }
  }
}

/* Aktionswochen
   ========================================================================== */

body {
  &.aktionswochen.gewinnspiel {
    margin-top:80px;
    @include gridle_state(phone) {
      margin-top:60px;
    }
    main.content {
      background-position:50% 0;
      padding-top:38%;
    }
  }
  &.aktionswochen {
    background: $sander_gold_hell;
  }
}

.page-section-download {
  .main-text {
    padding-top:0;
  }
}


/* ==========================================================================
   To be ordered
   ========================================================================== */

// This is a generic element to be used inside page sections, that will respect the correct
// outer margins, however, elements inside will not necessarily bealigned to the grid.

.row-outer, .page-section > .row-outer {
  padding-bottom:0;
}

.row-outer > .inner {
  @include gridle(10 push 1);
  @include gridle-state(phone) {
    @include gridle(12 push 0);
    padding: 0 25px;
  }
}
