

/* Page Section
   ========================================================================== */

.page-section {
  background:white;
  min-height:auto;
  position:relative;
  background-position:0% 0%;
  background-size:cover;
  background-repeat: no-repeat;
  margin-left:20px;
  margin-top:40px;

  h2 {
    margin-top:0;
  }

  .main-text {
    padding: 60px 0 60px 0;
    margin:100px 0 40px 0;
    &.right {
      margin-left:0;
      padding-left:105px;
    }
    &.transparent {
      background:rgba(white,0.9);
    }
    &.no-bg {
      background:transparent;
    }
    &.single {
      padding-top:0;
      margin-top:0;
      margin-bottom:0;
      padding-bottom:0;
    }
    h2 {
      margin-right:-20px;
    }

    @include clearfix;

    // Needed for Über uns / Unternehmensaufbau
    @include gridle_state(desktop-tablet-s) {
     &.fixed-width {
        // float:right;
        width:610px;
        box-sizing:border-box;
        margin-right: auto;
        margin-left: auto;
        // padding-right:0;
        // padding-left:0;
      }
    }
    @include gridle_state(desktop-only) {
      &.fixed-width {
        float:right;
      }
    }
  }

  &.with-padding {
    padding-top:140px;
    padding-bottom:100px;
  }

  &.img-on-white {
    background-position:100% 0%;
    background-size:contain;
  }

  .section-label {
    background:$sander_gold_hell;
    height:40px;
    text-transform: uppercase;
    letter-spacing:0.160em;
    color:white;
    line-height:40px;
    display:inline-block;
    padding-left:40px;
    padding-right:60px;
    font-size:14px;
    position:absolute;
    top:40px;
    margin:0;
    left:-20px;

    &:after {
      content:'';
      display:block;
      position:absolute;
      left:0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 20px 0;
      border-color: transparent $sander_gold_dunkel transparent transparent;
    }
  }

  .icon.icon-apple-book-gold {
    width:28px;
    height:28px;
    background-size:28px 28px;
  }

  .icon.icon-job-gold {
    width:50px;
    height:35px;
    background-size:50px 35px;
  }

  .icon.icon-jobportal {
    width:120px;
    height:35px;
    background-size:120px 35px;
    padding-left: 10px;
  }

  &.falloff {
    background:linear-gradient(rgba(white,1), rgba(white, 0));
  }

  > .row {
    margin-left:-30px;
    .col-content {
      @include gridle(4 push 1);
      order:1;
    }
    .col-remaining {
      @include gridle(4 push 3);
      order:2;
    }
  }

  &.main-text-transparent > .row {
    .col-content {
      @include gridle(6 push 2);
    }
    .col-remaining {
      @include gridle(4 push 1);
    }
    .main-text {
      padding-left:1/6*100%;
      padding-right:1/6*100%;
    }
  }

  &.alt-order > .row {
    .col-content {
      order:2;
    }
    .col-remaining {
      order:1;
    }
  }

  &.left-translucent > .row {
    .col-content {
      // Overrides push
      left:20px;
    }
  }


  @include gridle_state('desktop-s') {
    > .row {
      margin-left:-30px;
      .col-content {
        @include gridle(5 push 1);
      }
      .col-remaining {
        @include gridle(4 push 2);
      }
    }
  }

  @include gridle_state('tablet') {
    margin-top:20px;
    margin-left:0;
    > .row {
      margin-left:-10px;
      .col-content {
          @include gridle(10 push 1);
      }
      .col-remaining {
          padding-bottom:40px;
          &.minheight {
            min-height:300px;
          }
          .remaining-background {
            background-position:0% 50%;
          }
          @include gridle(10 push 1);
      }
    }

    &.img-on-white {
      h2 {
        max-width:70%; /* Avoid collision with image in the top right */
      }
    }

    &.alt-order, &.main-text-transparent {
      background-image:none !important; /* Background image changes location, so this is required */
      > .row {
        padding-bottom:0;
        .col-content {
          @include gridle(10 push 1);
          order:1;
          .main-text {
            // max-width:initial;
            padding:0;
          }
        }
        .col-remaining {
          order:2;
          @include gridle(12 push 0);
          overflow:hidden;
          padding:50px 30px 50px 30px;
        }
        .remaining-background {
          position:absolute;
          top:0;
          right:10px;
          left:10px;
          bottom:0;
          background-position:50% 50%;
          background-size:cover;
        }
      }
    }

    .main-text {
      padding-top:0;
      padding-bottom:0;
      max-width:450px;
    }

    .section-label {
      top:20px;
    }

    &.with-padding {
      padding-top:100px;
      padding-bottom:60px;
    }
  }

  @include gridle_state('phone') {
    &.with-padding {
      padding-top:40px;
      padding-bottom:40px;
    }
    &, &.alt-order {
      > .row .col-content, > .row .col-remaining {
        @include gridle(12 push 0);
        padding-right: 30px;
        padding-left: 30px;
      }
    }
    .main-text{
      margin:40px 0 40px 0;
    }
    .section-label {
      display:none;
    }

    &.empty {
      padding-top:40px;
      padding-bottom:40px;
    }
  }
}




/* Standard section content overrides
   ========================================================================== */

.page-section .section-content-standard {
  .main-text {
    margin:0;
    padding:0;
  }
}

/* News section styling
   ========================================================================== */

.page-section.page-section-news {
  .teaser-news-content {
    @include gridle(5);
  }
  .teaser-news-list {
    @include gridle(6 push 1);
  }
  @include gridle_state(tablet) {
    .teaser-news-content {
      @include gridle(12);
    }
    .teaser-news-list {
      @include gridle(12 push 0);
    }
  }
}

#flex-container-saisonaleangebote-justify {
  text-align: center;
}



#flex-container-sozial-justify {
  text-align: left;
}

#page-wrap {
text-align: left;
width: 86%;
margin: 0 auto;
}

.flex-container-sozial {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: left;
  background-color: White;
  }

  .flex-container-sozial>div {
    background-color: white;
    max-width: 387px;
    margin: 0px;
    padding: 0px;
    }
/* everything over */
    @media screen and (min-width: 1185px) {

      .flex-container-sozial>div {
      max-width: 430px;
      right: auto;
      margin-left: 0px;
      }
      .flex-container-sozial>h2 {
        max-width: 100%;
        }
      #tafelimg {
          max-width: 470px;
      }
    }

/* everything under */
    @media screen and (max-width: 1200px) {

      .flex-container-sozial>div {
      max-width: 100%;
      right: auto;
      margin-left: 0px;
      }
      .flex-container-sozial>h2 {
        max-width: 100%;
        }
        .flex-container-sozial {
          display: flex;
          flex-direction: column-reverse;
          flex-wrap: wrap;
          align-items: stretch;
          justify-content: left;
          background-color: White;
          }
          #tafelimg {
            max-width: 470px;
          }
    }

// Auszeichnungen

    #flex-container-auszeichnungen-justify {
      text-align: left;
    }

    #page-wrap-auszeichnungen {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    }

    .flex-container-auszeichnungen {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      }

      .flex-container-auszeichnungen>div {
        max-width: 387px;
        margin: 0px;
        padding: 0px;
        }

        #awardlogos {
          max-width: 470px;
        }
    /* everything over 1228px*/
        @media screen and (min-width: 1228px) {

          .page-section {
            &.img-on-white  #_auszeichnungen {
               background-size:cover;
             }
           }

          .flex-container-auszeichnungen>div {
          max-width: 485px;
          right: auto;
          margin-left: 0px;
          }
          .flex-container-auszeichnungen>h2 {
            max-width: 100%;
            }
            #awardlogos {
              max-width: 470px;
              margin-bottom: 60px;
              margin-right: 60px;
            }
        }

    /* everything under 1228px*/
        @media screen and (max-width: 1228px) {

          .page-section {
            &.img-on-white-responsive {
              background-position:100% 0%;
              background-size:110%;
           }}
           #page-wrap-auszeichnungen {
            text-align: center;
            width: 90%;
            margin: 0 auto;
            }

          .flex-container-auszeichnungen>div {
          max-width: 600px;
          right: auto;
          margin-left: 0px;
          }
          .flex-container-auszeichnungen>h2 {
            max-width: 100%;
            }
            .flex-container-auszeichnungen {
              display: flex;
              flex-direction: center;
              flex-wrap: wrap;
              align-items: stretch;
              justify-content: center;
              }
              #awardlogos {
                max-width: 470px;
                margin-bottom: 60px;
                margin-right: 0px;
              }
        }

            /* everything under 575px*/
            @media screen and (max-width: 575px) {

                        .page-section {
                          &.img-on-white-responsive {
                            background-position:100% 0%;
                            background-size:200%;
                         }}

                        .flex-container-auszeichnungen>div {
                        max-width: 100%;
                        right: auto;
                        margin-left: 0px;
                        }
                        .flex-container-auszeichnungen>h2 {
                          max-width: 100%;
                          }
                          .flex-container-auszeichnungen {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            align-items: stretch;
                            justify-content: left;
                            }
                            #awardlogos {
                              max-width: 470px;
                              margin-bottom: 60px;
                            }
                      }

.flex-container-saisonaleangebote {
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: stretch;
justify-content: center;
background-color: White;
}

.flex-container-saisonaleangebote>div {
background-color: white;
width: 400px;
margin: 0px;
padding: 0px;
}

.myboxes {
height: auto;
}

.flexbox-shadow {
  box-shadow: 0 0 30px #333;
}

.flexbox-bottom-right {
  position: absolute;
  bottom: 70px;
  right: 10px;
}

.flexbox-bottom-right-top {
  position: absolute;
  bottom: 25px;
  right: 10px;
}

.flexbox-container {
  position: relative;
  text-align: center;
}

.flexbox-emotion-subhead {
  color: #333333;
  font-size: 20px;
  line-height: 20px;
  min-width: auto;
}

#makeitsmall {
  max-width:200px !important;
}

