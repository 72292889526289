.section-job {


  .btn-back {
    margin-top:0;
    margin-bottom:60px;
  }

  .job-main {
    margin-top:40px;
  }

  .job-column-left, .job-column-right {
    @include gridle(6);
  }

  @include gridle_state(tablet) {
   .btn-back {
     margin-bottom:40px;
   }
  }

  @include gridle_state(tablet-s) {
    .job-column-left, .job-column-right {
      @include gridle(12);
    }
  }

  @include gridle_state(phone) {
    .btn-back {
      margin-bottom:20px;
    }
  }

  .single-main h2 {
    margin-bottom:20px;
  }
}
