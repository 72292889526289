.section-gewinnspiel-form {
    background:rgba(255,255,255,0.9);

    padding-top:80px;
    padding-bottom:80px;

    .sander-form .intro {
        max-width: initial;
        h2 {
            color:$sander_gold_dunkel;
            font-size:26px;
            line-height:31px;
        }
    }

    .lora-contact-title {
        margin-top:0;
        padding-top:5px;
    }

    .sander-form .radio {
        display: block;
        margin-bottom:20px;
        span {
            padding-left:10px;
        }
    }

    @include gridle_state(tablet) {
    padding-top:40px;
        .lora-contact-title {
            margin-top:40px;
        }
    }
    .sander-form .intro {
        h2 {
            font-size:22px;
            line-height:28px;
        }
    }
}